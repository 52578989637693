/* eslint-disable no-underscore-dangle,class-methods-use-this */
module.exports = class Section {
  constructor(section, userPermissions) {
    this._label = section.label;
    this._url = section.url;
    this._permission = section.permission;
    this._featureFlags = section.feature_flags;
    this._mobile = section.mobile;
    this._userPermissions = userPermissions;
    this._isNew = section.is_new || false;
  }

  getLabel() {
    return this._label;
  }

  getId() {
    return this._id;
  }

  getUrl() {
    return this._url;
  }

  getPermission() {
    return this._permission;
  }

  getMobile() {
    return this._mobile;
  }

  getFeatureFlags() {
    return this._featureFlags;
  }

  /**
   * Return if section is visible, based on the given userPermissions.
   * If section does not have a permission associated or the given permission does not exists in
   * userPermissions, section is considered visible.
   * @return {boolean}
   */
  isVisible() {
    const userPermissions = this._userPermissions;
    // If section does not have a permission associated or the given permission does not exists
    const permissionNeeded = this.getPermission() !== undefined
      && userPermissions[this.getPermission()] !== undefined;

    return (!permissionNeeded || userPermissions[this.getPermission()] === true)
      && this.isFeatureEnabled();
  }

  /**
   * Return if section is mobile-ready.
   * @return {boolean}
   */
  isMobile() {
    return this.getMobile();
  }

  /**
   * Return true if all feature flag related to section are enabled.
   * @returns {boolean}
   */
  isFeatureEnabled() {
    if (this.getFeatureFlags() && this.getFeatureFlags().length > 0) {
      return this.getFeatureFlags().every((flag) => Wethod.featureService.isEnabled(flag));
    }
    return true;
  }

  /**
   * Return true if section must be considered new.
   * @return {boolean}
   */
  isNew() {
    return this._isNew;
  }

  /**
   * Return if section is blocked.
   * A blocked section is still visible in nav but cannot be accessed.
   * @return {boolean}
   */
  isBlocked() {
    return false;
  }

  hasUrl(url) {
    return this.getUrl() === url;
  }
};
