const React = require('react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');

const InsightLevelTableViewMoneyCell = ({ children, className, ...rest }) => {
  const actualClassName = `${className} wethod-table__cell--money`;

  return (
    <TableCell className={actualClassName} {...rest}>
      {children}
    </TableCell>
  );
};

module.exports = InsightLevelTableViewMoneyCell;
