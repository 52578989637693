const React = require('react');
const PropTypes = require('prop-types');
const TableRow = require('./TableRow.react');
const TableColumnList = require('./models/TableColumnList');

/**
 * A table row to use inside TableHead in order to display totals grouped by column.
 */
const TableSummaryRow = ({ children, variant, scrollBarWidth, visibleColumns }) => (
  <TableRow summary
    scrollBarWidth={scrollBarWidth}
    variant={variant}
    visibleColumns={visibleColumns}>
    {children}
  </TableRow>
);

TableSummaryRow.defaultProps = {
  children: [],
  variant: 'header',
  scrollBarWidth: null,
  visibleColumns: new TableColumnList(),
};

TableSummaryRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /**
   * Passed to children.
   *
   * @see TableCell
   */
  variant: PropTypes.oneOf(['header']),
  scrollBarWidth: PropTypes.number,
  /**
   * List of visible TableColum.
   * If empty, this props is ignored and all column are considered visible.
   */
  visibleColumns: PropTypes.instanceOf(TableColumnList),
};

module.exports = TableSummaryRow;
