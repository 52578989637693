module.exports = class ChartConfigTableColumn {
  /**
   * @param {string} key Key to use to find the related item attribute
   * @param {string} label
   * @param {string} sortKey Key to use for sorting by this column
   */
  constructor(key, label, sortKey) {
    this.key = key;
    this.label = label;
    this.sortKey = sortKey;
  }
};
