const TimeDimension = require('../models/ChartConfigDimension/ChartConfigTimeDimension');
const TimeIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterTimeIncluded');
const ProbabilityDimension = require('../models/ChartConfigDimension/ChartConfigProbabilityDimension');
const ProbabilityIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterProbabilityIncluded');
const ClientDimension = require('../models/ChartConfigDimension/ChartConfigClientDimension');
const ClientNotIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterClientNotIncluded');
const ClientIncludedFilter = require('../models/ChartConfigFilter/ChartConfigFilterClientIncluded');

const BuilderService = {
  /**
   * Derive filter by given point and related chart.
   * @param {BarChartPoint} selectedPoint
   * @param {Chart} chart
   * @param {BarChartPoint[]} points
   */
  buildFilterByPoint(selectedPoint, chart, points) {
    const { dimensions } = chart.config;
    const filters = [];
    const axisList = Object.keys(dimensions);
    // All dimensions except the one related to section data source can be used to create filters
    const filterableAxisList = axisList
      .filter((axis) => dimensions[axis] && dimensions[axis].key !== chart.dataSource);
    filterableAxisList.forEach((axis) => {
      const dimension = dimensions[axis];
      if (dimension instanceof TimeDimension) {
        if (dimension.grouping.type === 'period') {
          const precision = dimension.grouping.value;
          const filter = new TimeIncludedFilter([selectedPoint[axis]], precision);
          filters.push(filter);
        }
      } else if (dimension instanceof ProbabilityDimension) {
        const { values } = dimension.findCluster(selectedPoint[axis]);
        const filter = new ProbabilityIncludedFilter(values);
        filters.push(filter);
      } else if (dimension instanceof ClientDimension) {
        // if clients are grouped then find the "all the rest" index
        if (dimension.grouping.isGrouped()) {
          const isOthersGroup = points.findIndex((p) => p[axis] === selectedPoint[axis])
            === dimension.grouping.getOthersGroupIndex();
          if (isOthersGroup) {
            // If click happens on all the rest then use NOT IN filter and pass all non-selected points
            const nonSelectedPoints = points.filter((p) => p.id !== selectedPoint.id);
            const nonSelectedPointsValues = nonSelectedPoints.map((p) => p[axis]);
            const filter = new ClientNotIncludedFilter(nonSelectedPointsValues);
            filters.push(filter);
          } else {
            // Otherwise use IN filter and pass the selected point
            const filter = new ClientIncludedFilter([selectedPoint[axis]]);
            filters.push(filter);
          }
        } else {
          // Otherwise use IN filter
          const filter = new ClientIncludedFilter([selectedPoint[axis]]);
          filters.push(filter);
        }
      } else {
        throw new Error(`'${dimension.key}' is not a supported filter.`);
      }
    });

    return filters;
  },
};

module.exports = BuilderService;
