module.exports = class BarChartPoint {
  /**
   * @param x {number | string}
   * @param y {number | string}
   * @param color {?string}
   */
  constructor(x, y, color = null) {
    this.x = x;
    this.y = y;
    this.color = color;
    this.id = `${x}${y}${color}`;
  }
};
