class ChartConfigFilter {
  /**
   * @param {string} type
   * @param {string} target
   */
  constructor(target, type) {
    this.setType(type);
    this.target = target;
  }

  /**
   * @param {string} type
   */
  setType(type) {
    const isTypeAvailable = ChartConfigFilter.AVAILABLE_TYPES.includes(type);
    if (!isTypeAvailable) {
      throw new Error(`'${type}' is not valid as ChartConfigFilter type.`);
    }
    this.type = type;
  }
}

ChartConfigFilter.TYPE_IN = 'in';
ChartConfigFilter.TYPE_NOT_IN = 'not-in';
ChartConfigFilter.TYPE_RANGE = 'range';
ChartConfigFilter.TYPE_RELATIVE = 'relative';

ChartConfigFilter.AVAILABLE_TYPES = [
  ChartConfigFilter.TYPE_IN,
  ChartConfigFilter.TYPE_NOT_IN,
  ChartConfigFilter.TYPE_RANGE,
  ChartConfigFilter.TYPE_RELATIVE,
];

module.exports = ChartConfigFilter;
