const React = require('react');
const Button = require('../../../../../../common/react/Button/RoundedButton.react');
const T = require('../../../../../../common/react/Typography/Typography.react');

/**
 * Tab used to select and show a Chart in an InsightLevelChartPicker.
 * @param {number} id
 * @param {string} title
 * @param onClick
 * @param {boolean} selected
 * @return {JSX.Element}
 * @constructor
 */
const InsightLevelChartTab = ({ id, title, onClick, selected = false }) => {
  function handleClick() {
    onClick(id);
  }
  const getButtonClassName = () => {
    let name = 'wethod-insight-level__chart-picker-tab-button';
    if (selected) {
      name = `${name} ${name}--selected`;
    }
    return name;
  };

  return (
    <li className="wethod-insight-level__chart-picker-tab" role="tab" aria-selected={selected}>
      <Button onClick={handleClick}
        label={title}
        className={getButtonClassName()}
        border={false}>
        <T size={T.SIZES.PX14}>
          {title}
        </T>
      </Button>
    </li>
  );
};

module.exports = InsightLevelChartTab;
