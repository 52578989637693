const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterTime extends ChartConfigFilter {
  /**
   * @abstract
   * @param {string} type
   * @param {string} precision
   */
  constructor(type, precision) {
    super(ChartConfigFilterTime.TARGET, type);
    this.precision = precision;
  }
}

ChartConfigFilterTime.TARGET = 'time';

module.exports = ChartConfigFilterTime;
