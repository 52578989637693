'use strict';

var FeatureService = require('../../../services/FeatureService');
var PricelistModel = require('../../../models/Pricelist');
/* eslint-disable default-case */

Wethod.module('SettingsApp.Team', function (Team, Wethod, Backbone, Marionette) {
  this.TeamLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    className: 'fluid',
    template: '#teamStructure',
    regions: {
      header: '[data-region="teamHeader"]',
      invitation: '[data-region="teamInvitation"]',
      actual: '[data-region="teamActual"]',
      modal: '[data-region="appDialog"]',
    },
  });

  this.InvitationLayoutView = Marionette.LayoutView.extend({
    className: 'grid',
    template: '#teamInvitationLayout',
    regions: {
      invitation: '[data-region="invitation"]',
      levels: '[data-region="invitationLevels"]',
      roles: '[data-region="invitationRoles"]',
    },
    ui: {
      showNewInvitationButton: '[data-action="newInvitationButton"]',
      showMultipleInvitationButton: '[data-action="multipleInvitationButton"]',
      sendNewInvitationButton: '[data-action="sendNewInvitation"]',
      sendNewInvitationButtonLabel: '[data-region="sendNewInvitationLabel"]',
      closeInvitationButton: '[data-action="closeInvitation"]',
      newInvitationRow: '.newInvitation',
      email: '[name="invitationEmail"]',
    },
    events: {
      'click @ui.showNewInvitationButton': 'prepareNewInvitation',
      'click @ui.sendNewInvitationButton': 'sendNewInvitation',
      'click @ui.closeInvitationButton': 'hideNewInvitation',
      'click @ui.email': 'resetError',
    },
    prepareNewInvitation: function () {
      this.ui.email.val('');
      this.ui.sendNewInvitationButtonLabel.text('invite');
      this.ui.sendNewInvitationButton.removeClass('disabled');
      Team.Controller.showNewInvitation();
    },
    showNewInvitationButton: function () {
      this.ui.showNewInvitationButton.removeClass('disabled');
      // this.ui.showMultipleInvitationButton.removeClass('disabled');
    },
    isInvitationButtonEnabled: function () {
      return this.ui.showNewInvitationButton.hasClass('disabled');
    },
    showNewInvitation: function () {
      this.ui.newInvitationRow.fadeIn(100);
    },
    resetError: function () {
      $('#newInvitationErrorBox').hide();
    },
    hideNewInvitation: function () {
      this.clearNewInvitation();
      this.ui.newInvitationRow.fadeOut(0);

      // todo remove this
      $('#newInvitationErrorBox').remove();
    },
    clearNewInvitation: function () {
      this.ui.email.val('');
      this.ui.sendNewInvitationButtonLabel.text('invite');
      this.ui.sendNewInvitationButton.removeClass('disabled');
    },
    sendNewInvitation: function () {
      if (!this.ui.sendNewInvitationButton.hasClass('disabled')) {
        var invitationData = {
          employee_email: _.escape(this.ui.email.val().trim()),
          employee_role: this.$el.find('.roleSelect').val(),
          employee_level: this.$el.find('.levelSelect').val(),
          url: window.location.origin + window.location.pathname,
        };

        Team.checkPlan(this, 1, 'insert', invitationData);
      }
    },
    errorNewInvitation: function () {
      // todo do a better notification system
      this.$el.find('.newInvitation').find('#newInvitationErrorBox').remove();
      this.$el.find('.newInvitation')
        .append('<span id="newInvitationErrorBox" style="color:red;display:inline-block;padding:5px;">! invalid email address</span>');
    },
  });

  this.InvitationItemView = Marionette.ItemView.extend({
    template: '#invitationTemplate',
    tagName: 'ul',
    className: 'col_16 invitationRow table',
    ui: {
      deleteButton: '[data-action="removeInvitation"]',
    },
    events: {
      'click @ui.deleteButton': 'deleteInvitation',
    },
    deleteInvitation: function (e) {
      e.preventDefault();
      var data = {
        title: 'REVOKE INVITATION',
        text: 'Are you sure you want to revoke the invitation?',
        buttonOkText: 'REVOKE',
        buttonKoText: 'GO BACK',
        action: 'uninvite',
      };

      Team.showModal(data, this);
    },
  });

  this.InvitationEmptyView = Marionette.ItemView.extend({
    tagName: 'ul',
    className: 'col_16 invitationRow table',
    template: '#invitationEmptyTemplate',
  });

  this.InvitationsCollectionView = Marionette.CollectionView.extend({
    childView: Team.InvitationItemView,
    emptyView: Team.InvitationEmptyView,
  });

  this.ActualLayoutWithBUView = Marionette.LayoutView.extend({
    template: '#actualLayoutWithBU',
    className: 'grid bottomSpace',
    regions: {
      actual: '[data-region="actual"]',
      tagCategoryInput: '[data-region="tagCategory"]',
    },
    ui: {
      importTagsButton: '[data-action="importTags"]',
      exportTagsButton: '[data-action="exportTags"]',
    },
    events: {
      'click @ui.importTagsButton': 'onImportTagsClick',
      'click @ui.exportTagsButton': 'onExportTagsClick',
    },
    initialize: function (options) {
      this.options = options;
      if (options.jobTitles) {
        var companyHasActiveJobTitles = options.jobTitles.length > 0;
        this.model.set({ companyHasActiveJobTitles: companyHasActiveJobTitles });
      }
    },
    onExportTagsClick: function () {
      Wethod.request('team:metadata:export');
      var data = {
        title: 'EXPORTING METADATA',
        text: 'You will receive a document shortly in your mailbox.',
        buttonKoText: 'OK',
        buttonOkText: null,
      };
      Team.showModal(data, this);
    },
    onImportTagsClick: function () {
      Team.showImportTagsModal();
    },
  });

  this.ActualLayoutView = Marionette.LayoutView.extend({
    template: '#actualLayout',
    className: 'grid bottomSpace',
    regions: {
      actual: '[data-region="actual"]',
      tagCategoryInput: '[data-region="tagCategory"]',
    },
    ui: {
      importTagsButton: '[data-action="importTags"]',
      exportTagsButton: '[data-action="exportTags"]',
    },
    events: {
      'click @ui.importTagsButton': 'onImportTagsClick',
      'click @ui.exportTagsButton': 'onExportTagsClick',
    },
    initialize: function (options) {
      this.options = options;
      var companyHasActiveJobTitles = options.jobTitles.length > 0;
      this.model.set({ companyHasActiveJobTitles: companyHasActiveJobTitles });
    },
    onExportTagsClick: function () {
      Wethod.request('team:metadata:export');
      var data = {
        title: 'EXPORTING METADATA',
        text: 'You will receive a document shortly in your mailbox.',
        buttonKoText: 'OK',
        buttonOkText: null,
      };
      Team.showModal(data, this);
    },
    onImportTagsClick: function () {
      Team.showImportTagsModal();
    },
  });

  this.TagCategoryItemView = Marionette.ItemView.extend({
    template: function (serializedModel) {
      var template = $('#tagCategoryTemplate').html();
      return _.template(template)(serializedModel);
    },
    tagName: 'li',
    className: 'col_8 left',
    ui: {
      category: '[data-action="insertTagCat"]',
    },
    events: {
      'change @ui.category': 'saveCategory',
    },
    saveCategory: function () {
      var name = _.escape(this.ui.category.val().trim());

      if (name !== '') {
        Team.saveCategory(this, name);
      } else {
        this.ui.category.val(this.model.get('name'));
      }
    },
  });

  this.TagBusinessUnitItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'col_8 left',
    template: '#tagBusinessUnitTemplate',
  });

  this.TagCategorysCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'left col_4',
    initialize: function (options) {
      this.options = options;
      var companyHasActiveJobTitles = options.jobTitles.length > 0;
      if (companyHasActiveJobTitles) {
        this.el.classList.add('actualRowInputWithJobTitle__tag-header');
      } else {
        this.el.classList.add('actualRowInput__tag-header');
      }
    },
    getChildView: function (item) {
      if (item.get('name') === 'Business Unit') {
        return Team.TagBusinessUnitItemView;
      }
      return Team.TagCategoryItemView;
    },
  });

  this.ActualItemView = Marionette.ItemView.extend({
    template: '#actualTemplate',
    tagName: 'ul',
    className: 'grid actualRow',
    ui: {
      levels: '[data-region="actualLevels"]',
      jobTitlesInput: '[data-action="selectJobTitle"]',
      jobTitlesList: '[data-region="actualJobTitleList"]',
      roles: '[data-region="actualRoles"]',
      tagAInput: '[data-action="selectTagA"]',
      tagBInput: '[data-action="selectTagB"]',
      tagCInput: '[data-action="selectTagC"]',
      tagListA: '[data-region="tagListA"]',
      tagListB: '[data-region="tagListB"]',
      tagListC: '[data-region="tagListC"]',
      moreActions: '[data-action="moreActions"]',
      moreActionSelectEl: '[data-region="moreOptionSelct"]',

      archiveIcon: '[data-region="emplyeeArchiveLabel"]',
      archiveEl: '[data-action="archiveProject"]',
      archiveLabelEl: '[data-region="archiveProjectLabel"]',

      showHidePlanningIcon: '[data-region="showHidePlanningIcon"]',
      showHidePlanning: '[data-action="showHidePlanning"]',
      showHidePlanningLabel: '[data-region="showHidePlanningLabel"]',

      timesheetNotNeededIcon: '[data-region="timesheetNotNeededIcon"]',
      timesheetNotNeeded: '[data-action="timesheetNotNeeded"]',
      timesheetNotNeededLabel: '[data-region="timesheetNotNeededLabel"]',

      locationIcon: '[data-region="locationIcon"]',
      workHourCapacityIcon: '[data-region="workHourCapacityIcon"]',

      allocationManager: '[data-action="allocationManager"]',
      allocationManagerLabel: '[data-region="allocationManagerLabel"]',
      allocationManagerIcon: '[data-region="allocationManagerIcon"]',

      pricelistIcon: '[data-region="pricelistIcon"]',
      setPricelist: '[data-action="pricelist"]',

      editUserCode: '[data-action="editUserCode"]',
      editUserCodeLabel: '[data-region="editUserCodeLabel"]',

      showChangeLogLabel: '[data-region="changeLogLabel"]',
      showChangeLog: '[data-action="changeLog"]',
    },
    events: {
      'click @ui.jobTitlesInput': function (e) {
        this.autocompleteJobTitles(e, this.ui.jobTitlesInput, this.ui.jobTitlesList);
      },
      'keyup @ui.jobTitlesInput': function (e) {
        this.autocompleteJobTitles(e, this.ui.jobTitlesInput, this.ui.jobTitlesList);
      },
      'click @ui.tagAInput': function (e) {
        this.autocompleteTag(e, 'A', this.ui.tagAInput, this.ui.tagListA);
      },
      'click @ui.tagBInput': function (e) {
        this.autocompleteTag(e, 'B', this.ui.tagBInput, this.ui.tagListB);
      },
      'click @ui.tagCInput': function (e) {
        this.autocompleteTag(e, 'C', this.ui.tagCInput, this.ui.tagListC);
      },
      'keyup @ui.tagAInput': function (e) {
        this.autocompleteTag(e, 'A', this.ui.tagAInput, this.ui.tagListA);
      },
      'keyup @ui.tagBInput': function (e) {
        this.autocompleteTag(e, 'B', this.ui.tagBInput, this.ui.tagListB);
      },
      'keyup @ui.tagCInput': function (e) {
        this.autocompleteTag(e, 'C', this.ui.tagCInput, this.ui.tagListC);
      },
      'click @ui.moreActions': 'moreActions',
      'click @ui.archiveEl': 'archiveEmployee',
      'click @ui.showHidePlanning': 'showHideEmployee',
      'click @ui.timesheetNotNeeded': 'toggleTimesheetNotNeeded',
      'click @ui.editUserCode': 'editUserCode',
      'click @ui.allocationManager': 'showAllocationManagerModal',
      'click @ui.setPricelist': 'showPricelistModal',
      'click @ui.showChangeLog': 'showChangeLogModal',
    },
    initialize: function (options) {
      this.options = options;
      var tagCategory = [];
      var emptyTagCatA = false;
      var emptyTagCatB = false;
      var emptyTagCatC = false;
      // assegno al modello le categorie dei tag
      for (var i = 0; i < this.options.tagCategory.length; i++) {
        if (_.isNull(this.options.tagCategory[i].id)) {
          if (i === 0) {
            emptyTagCatA = true;
          } else if (i === 1) {
            emptyTagCatB = true;
          } else {
            emptyTagCatC = true;
          }
        }

        tagCategory.push({
          id: this.options.tagCategory[i].id,
          name: this.options.tagCategory[i].name,
          sort: i,
        });
      }

      this.options.model.set({ emptyTagCatA: emptyTagCatA });
      this.options.model.set({ emptyTagCatB: emptyTagCatB });
      this.options.model.set({ emptyTagCatC: emptyTagCatC });
      this.options.model.set({ tagCategory: tagCategory });
      var companyHasActiveJobTitles = options.jobTitles.length > 0;
      this.options.model.set({ companyHasActiveJobTitles: companyHasActiveJobTitles });
      this.model.set({
        showPricelistIcon: Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET),
      });
    },
    onShow: function () {
      var employeeId = this.model.get('employee').id;
      var levelId = this.model.get('level').id;
      var pricelist = this.model.get('price_list');
      var levelCollectionView = Team.Controller
        .getLevels(employeeId, levelId, pricelist ? pricelist.id : null);
      levelCollectionView.on('change:level:model', this.updateLevelModel, this);
      var jobTitle = this.options.model.get('job_title').id && this.options.model.get('job_title').name ? this.options.model.get('job_title').name : '';
      this.ui.jobTitlesInput.val(jobTitle);
      levelCollectionView.on('reset:job:title', this.resetJobTitle, this);
      var roleId = this.model.get('role').id;
      var roleCollectionView = Team.Controller.getRoles(employeeId, roleId);

      this.ui.levels.html(levelCollectionView.render().$el);

      this.ui.roles.html(roleCollectionView.render().$el);

      if (Wethod.featureService.isEnabled(FeatureService.HIERARCHY_BUDGET)) {
        this.ui.pricelistIcon.text('Pricelist:' + pricelist.name);
        this.ui.pricelistIcon.show();
        var today = moment();

        var isPricelistAvailableInThePast = !pricelist.available_from
          || moment(pricelist.available_from).isSameOrBefore(today);
        var isPricelistAvailableInTheFuture = !pricelist.available_to
          || moment(pricelist.available_to).isAfter(today);
        var isPricelistAvailable = isPricelistAvailableInThePast && isPricelistAvailableInTheFuture;
        if (!isPricelistAvailable) {
          this.ui.pricelistIcon.addClass('empIconItem--error');
        } else {
          this.ui.pricelistIcon.removeClass('empIconItem--error');
        }
      }

      if (this.options.model.get('employee').type === 2) {
        this.ui.archiveIcon.show();
      }
      if (this.options.model.get('employee').visible === false) {
        this.ui.showHidePlanningIcon.show();
      }
      if (this.options.model.get('employee').timesheet_needed === false) {
        this.ui.timesheetNotNeededIcon.show();
      }

      if (this.options.model.get('employee').location !== null) {
        this.ui.locationIcon.show();
      }
      if (this.options.model.get('employee').workHourCapacity !== null) {
        this.ui.workHourCapacityIcon.show();
      }

      this.ui.allocationManagerIcon.show();
      var allocationManager = this.model.get('employee').allocation_manager;
      this.renderAllocationManager(allocationManager ? allocationManager.name + ' ' + allocationManager.surname : null);
    },
    updateLevelModel: function (levelId, levelName) {
      // we need to update the level model to have everything up to date
      var newLevel = {
        id: levelId,
        name: levelName,
      };
      this.model.set({ level: newLevel });
    },
    resetJobTitle: function () {
      // we need to update the job title model to have everything up to date
      var jobTitle = {
        id: null,
        name: null,
      };
      this.model.set({ job_title: jobTitle });
      this.ui.jobTitlesInput.val('');
    },
    renderAllocationManager: function (name) {
      var text = name ? 'Allocation manager: ' + name : '';
      this.ui.allocationManagerIcon.text(text);
    },
    moreActions: function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.ui.moreActionSelectEl.hasClass('isVisible')) {
        if (this.options.model.get('employee').type === 2) {
          this.ui.archiveLabelEl.text('UNARCHIVE');
        } else {
          this.ui.archiveLabelEl.text('ARCHIVE');
        }
        if (this.options.model.get('employee').visible === false) {
          this.ui.showHidePlanningLabel.text('SHOW IN PLANNING');
        } else {
          this.ui.showHidePlanningLabel.text('HIDE FROM PLANNING');
        }
        if (this.options.model.get('employee').timesheet_needed === false) {
          this.ui.timesheetNotNeededLabel.text('SET TIMESHEET REQUIRED');
        } else {
          this.ui.timesheetNotNeededLabel.text('SET TIMESHEET OPTIONAL');
        }
        $('.isVisible').removeClass('isVisible');

        this.ui.moreActionSelectEl.addClass('isVisible');
      } else {
        this.ui.moreActionSelectEl.removeClass('isVisible');
      }

      // Hide select when click on 'html'
      Wethod.onHtmlClick('[data-region="moreOptionSelct"]', 'isVisible', 'remove');
    },
    archiveEmployee: function (e) {
      e.preventDefault();
      var data = {};
      if (this.options.model.get('employee').type === 2) {
        data = {
          title: 'UNARCHIVE USER',
          text: 'As an active user #userName# will get access to wethod as usual. <br/>Are you sure you want the user back in the game?',
          buttonOkText: 'YES',
          buttonKoText: 'REFUSE',
          action: 'unarchive',
        };
        var userName = this.options.model.get('person').name + ' ' + this.options.model.get('person').surname;
        data.text = data.text.replace('#userName#', userName);
      } else {
        data = {
          title: 'THANKS FOR COMING!',
          text: 'By placing this user to the Archive, you cast him/her out of Wethod for good.<br/><br/> All datas and projects will be kept on anyway.',
          buttonOkText: 'ARCHIVE USER',
          buttonKoText: 'REFUSE',
          action: 'archive',
        };
      }
      Team.showModal(data, this);
    },
    showHideEmployee: function (e) {
      e.preventDefault();
      var data = {};
      if (this.options.model.get('employee').visible === false) {
        data = {
          title: 'SHOW USER ON PLANNING',
          text: 'Are you sure you want to be able to plan #userName# again?',
          buttonOkText: 'SHOW USER',
          buttonKoText: 'REFUSE',
          action: 'showOnPlanning',
        };
        var userName = this.options.model.get('person').name + ' ' + this.options.model.get('person').surname;
        data.text = data.text.replace('#userName#', userName);
      } else {
        data = {
          title: 'HIDE USER FROM PLANNING',
          text: 'By hiding this user you will no longer be able to plan him/her.',
          buttonOkText: 'HIDE USER',
          buttonKoText: 'REFUSE',
          action: 'hideFromPlanning',
        };
      }
      Team.showModal(data, this);
    },
    showAllocationManagerModal: function () {
      Team.showAllocationManagerModal(this);
    },
    showPricelistModal: function (e) {
      e.preventDefault();
      Team.showPricelistModal(this);
    },
    showChangeLogModal: function (e) {
      e.preventDefault();
      Team.showChangeLogModal(this);
    },
    toggleTimesheetNotNeeded: function (e) {
      e.preventDefault();
      var data = {};
      if (this.options.model.get('employee').timesheet_needed === false) {
        data = {
          title: 'SET TIMESHEET AS NEEDED',
          text: 'Are you sure you want to make timesheet mandatory for #userName#?',
          buttonOkText: 'YES',
          buttonKoText: 'NO',
          action: 'makeTimesheetNeeded',
        };
        var userName = this.options.model.get('person').name + ' ' + this.options.model.get('person').surname;
        data.text = data.text.replace('#userName#', userName);
      } else {
        data = {
          title: 'SET TIMESHEET AS OPTIONAL',
          text: 'This user will no longer receive alerts and reminders linked to timesheet. Do you want to proceed?',
          buttonOkText: 'OK',
          buttonKoText: 'CANCEL',
          action: 'makeTimesheetOptional',
        };
      }
      Team.showModal(data, this);
    },
    autocompleteJobTitles: function (e, input, tooltip) {
      e.stopPropagation();
      var val = _.escape(input.val().trim());
      $('.isVisible').removeClass('isVisible');

      if (val === '') {
        var employeeId = this.model.get('employee').id;
        Team.deleteEmployeeJobTitle(employeeId);
        this.resetJobTitle();
        $('.isVisible').removeClass('isVisible');
        if (!tooltip.hasClass('isVisible')) {
          tooltip.addClass('isVisible');
        }
        Team.autocompleteJobTitles(tooltip, val, this);
      } else if (
        (e.type === 'click' && val === '') || (e.type === 'keyup' && Wethod.Utility.allowChar(e.keyCode))
      ) {
        $('.isVisible').removeClass('isVisible');
        if (!tooltip.hasClass('isVisible')) {
          tooltip.addClass('isVisible');
        }
        Team.autocompleteJobTitles(tooltip, val, this);
      }

      // Hide select when click on 'html'
      Wethod.onHtmlClick(tooltip.selector, 'isVisible', 'remove');
    },
    autocompleteTag: function (e, catType, input, tooltip) {
      e.stopPropagation();
      var val = _.escape(input.val().trim());
      $('.isVisible').removeClass('isVisible');
      var tagModel = [];
      var that = this;

      switch (catType) {
        case 'A':
          _.each(this.model.get('tags'), function (tag) {
            if (tag.tag_emp_category_id === that.model.get('tagCategory')[0].id) {
              tagModel = tag;
            }
          });

          break;
        case 'B':
          _.each(this.model.get('tags'), function (tag) {
            if (tag.tag_emp_category_id === that.model.get('tagCategory')[1].id) {
              tagModel = tag;
            }
          });
          break;
        case 'C':
          _.each(this.model.get('tags'), function (tag) {
            if (tag.tag_emp_category_id === that.model.get('tagCategory')[2].id) {
              tagModel = tag;
            }
          });
          break;
      }

      if (!_.isEmpty(tagModel) && val === '') {
        Team.deleteEmployeeTag(this, catType, 'clear');

        $('.isVisible').removeClass('isVisible');
        if (!tooltip.hasClass('isVisible')) {
          tooltip.addClass('isVisible');
        }
        Team.autocomplete(tooltip, val, catType, this);
      } else if (
        (e.type === 'click' && val === '') || (e.type === 'keyup' && Wethod.Utility.allowChar(e.keyCode))
      ) {
        $('.isVisible').removeClass('isVisible');
        if (!tooltip.hasClass('isVisible')) {
          tooltip.addClass('isVisible');
        }
        Team.autocomplete(tooltip, val, catType, this);
      }

      // Hide select when click on 'html'
      Wethod.onHtmlClick(tooltip.selector, 'isVisible', 'remove');
    },
    editUserCode: function (e) {
      e.preventDefault();
      var data = this.model;

      Team.showUserCodeModal(data, this);
    },
  });
  this.ActualEmptyItemView = Marionette.ItemView.extend({
    template: '#actualEmptyTemplate',
    tagName: 'ul',
    className: 'grid actualRow',
  });
  this.ActualCollectionView = Marionette.CollectionView.extend({
    className: 'actualContainer',
    childView: Team.ActualItemView,
    emptyView: Team.ActualEmptyItemView,
  });

  // insert Tag
  this.InsertTagItemView = Marionette.ItemView.extend({
    template: '#teamInsertTagTemplate',
    ui: {
      tagNameEl: '[data-region="newTag" ]',
      saveButton: '[data-action="insertTag"]',
    },
    events: {
      'click @ui.tagNameEl': 'removeError',
      'click @ui.saveButton': 'save',
    },
    initialize: function (options) {
      this.options = options;
    },
    save: function (e) {
      e.preventDefault();
      var val = _.escape(this.ui.tagNameEl.val().trim());

      Team.deleteEmployeeTag(this.options.parent, this.options.model.attributes.catType, 'clear');
      Team.createNewTag(val, this.model.get('catType'), this.model.get('idCategory'), this.options.parent);
    },
  });

  // autocomplete
  this.AutocompleteItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#teamAutocompleteSelectTemplate',
    ui: {
      button: 'a',
    },
    events: {
      'click @ui.button': 'chooseHint',
    },
    initialize: function (options) {
      this.options = options;
    },
    chooseHint: function (e) {
      e.preventDefault();

      var hint = this.model.get('name');

      switch (this.options.catType) {
        case 'A':
          this.options.parent.ui.tagAInput.val(hint);
          break;
        case 'B':
          this.options.parent.ui.tagBInput.val(hint);
          break;
        case 'C':
          this.options.parent.ui.tagCInput.val(hint);
          break;
      }
      $('.isVisible').removeClass('isVisible');
      // input.val(hint);
      // select.removeClass('isVisible');
      // iconStatus.removeClass('error');

      // validate & save data
      Team.deleteEmployeeTag(this.options.parent, this.options.catType, 'clear');
      Team.updateEmployeeTag(this.model, this.options.catType, this.options.parent);
    },
  });
  this.EmptyTagHintsItemView = Marionette.ItemView.extend({
    template: '#teamAutocompleteNotFoundTemplate',
    tagName: 'li',
  });
  this.AutocompleteCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: '',
    childView: Team.AutocompleteItemView,
    emptyView: Team.EmptyTagHintsItemView,
  });

  // autocomplete
  this.JobTitleAutocompleteItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#teamAutocompleteSelectTemplate',
    ui: {
      button: 'a',
    },
    events: {
      'click @ui.button': 'chooseHint',
    },
    initialize: function (options) {
      this.options = options;
    },
    chooseHint: function (e) {
      e.preventDefault();
      var hint = this.model.get('name');
      var jobTitleId = this.model.get('id');
      var employeeId = this.options.parent.model.get('employee').id;
      $('.isVisible').removeClass('isVisible');

      // validate & save data
      Team.updateEmployeeJobTitle(hint, this.options.parent, employeeId, jobTitleId);
    },
  });

  this.JobTitleAutocompleteCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: '',
    childView: Team.JobTitleAutocompleteItemView,
  });

  this.LevelView = Marionette.ItemView.extend({
    template: '#levelsTemplate',
    tagName: 'option',
    attributes: function () {
      return {
        value: this.model.get('id'),
        selected: this.options.employeeLevel === this.model.get('id'),
      };
    },
  });

  this.LevelCollectionView = Marionette.CollectionView.extend({
    tagName: 'select',
    className: 'levelSelect',
    childView: Team.LevelView,
    events: {
      change: 'levelChange',
    },
    levelChange: function (e) {
      var levelId = e.target.value;
      var employeeId = this.options.employeeId;
      var pricelistId = this.options.pricelistId;
      var levelName = e.target.selectedOptions[0].innerText;
      Team.Controller.saveLevel(employeeId, levelId, pricelistId);
      this.triggerMethod('change:level:model', levelId, levelName, pricelistId);
      this.triggerMethod('reset:job:title');
    },
  });

  this.RoleView = Marionette.ItemView.extend({
    template: '#rolesTemplate',
    tagName: 'option',
    attributes: function () {
      return {
        value: this.model.get('id'),
        selected: this.options.employeeRole === this.model.get('id'),
        // Only a super user can create other super users
        disabled: Team.isSuperUserRole(this.model.attributes) && Wethod.userInfo.get('roles') !== 'ROLE_SUPER_USER',
      };
    },
  });

  this.RoleCollectionView = Marionette.CollectionView.extend({
    childView: Team.RoleView,
    tagName: 'select',
    className: 'roleSelect',
    attributes: function () {
      var options = this.options;
      var initialRole = options.collection.findWhere({ id: options.employeeRole })
        || options.collection.at(0);
      var isSuperUser = Team.isSuperUserRole(initialRole.attributes);

      var isTheLastSuperUser = !options.childViewOptions.moreThanOneSuperUser && isSuperUser;
      return {
        // Only a super user can edit another super user and at least two super users must exists
        // in order to change the role of one of them
        disabled: isTheLastSuperUser || (isSuperUser && Wethod.userInfo.get('roles') !== 'ROLE_SUPER_USER'),
      };
    },
    events: {
      change: 'roleChange',
    },
    roleChange: function (e) {
      var roleId = e.target.value;
      var employeeId = this.options.employeeId;

      Team.Controller.saveRole(employeeId, roleId);
    },
  });

  // modal
  var modalW = 448;
  var modalH = 200;
  this.ModalItemView = Marionette.ItemView.extend({
    template: '#teamModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.tamModalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      wrapMessage: '[data-region="modalStatusWrap"]',
      messageEl: '[data-message="statusModalAction"]',
      hiddenActionWrap: '[data-region="hiddenAction"]',
      hiddenAction: '[data-action="checkPayment"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
      'click @ui.hiddenAction': 'goToPayment',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    doOkAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        switch (this.model.get('action')) {
          case 'archive':
            Team.checkPlan(this.options.data, -1, 'archive', this);
            break;
          case 'unarchive':
            Team.checkPlan(this.options.data, -1, 'unarchive', this);
            break;
          case 'sendNewInvitation':
            Team.sendNewInvitation(this.options.data, this);
            break;
          case 'uninvite':
            Team.uninvite(this.options.data, this);
            break;
          case 'showOnPlanning':
            Team.showOnPlanning(this.options.data, this);
            break;
          case 'hideFromPlanning':
            Team.hideFromPlanning(this.options.data, this);
            break;
          case 'makeTimesheetNeeded':
            Team.makeTimesheetNeeded(this.options.data, this);
            break;
          case 'makeTimesheetOptional':
            Team.makeTimesheetOptional(this.options.data, this);
            break;
        }
      }
    },
    goToPayment: function (e) {
      e.preventDefault();
      Wethod.trigger('url:settings:payment:show');
    },
    cancelAction: function (e) {
      e.preventDefault();
      if (!this.ui.cancelButton.hasClass('disabled')) {
        this.destroy();
      }
    },
  });

  this.ImportTagsModalErrorListItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: function (serializedModel) {
      var name = serializedModel.error;
      var rows = serializedModel.rows.join(',');
      var html = '<span class="teamModalStructure__import-feedback-container"><span class="bold"><%= name %></span> in rows <span class="bold teamModalStructure__import-error-span"><%= rows %></span></span>';
      return _.template(html)({
        name: name,
        rows: rows,
      });
    },
  });

  this.ImportTagsModalErrorListView = Marionette.CollectionView.extend({
    className: 'teamModalStructure__import-tags-file-errors-list',
    tagName: 'ul',
    childView: this.ImportTagsModalErrorListItemView,
  });

  this.ImportTagsModalView = Marionette.LayoutView.extend({
    template: '#teamImportTagsModalTemplate',
    className: 'modalWrapper',
    isUploading: false,
    ui: {
      modalEL: '.tamModalStructure',
      cancelButton: '[data-action="modalCancel"]',
      uploadInput: '[data-action="importFileInput"]',
      uploadButton: 'label',
      fileName: '[data-message="fileName"]',
      feedback: '[data-message="feedback"]',
    },
    regions: {
      errors: '[data-region="errors"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',
      'change @ui.uploadInput': 'uploadFile',
    },
    initialize: function (options) {
      this.options = options;
    },
    uploadFile: function (e) {
      if (!this.isUploading) {
        var file = e.target.files[0];
        this.ui.uploadButton.addClass('disabled');
        this.errors.empty();
        this.ui.fileName.html(file.name);
        this.ui.feedback.html('Uploading...');

        var formData = new FormData();
        formData.append('import_file', file);

        $.when(Wethod.request('team:metadata:import', formData))
          .done(function () { // Close modal if upload successful
            this.ui.uploadButton.removeClass('disabled');
            this.ui.feedback.html('Upload complete!');
            setTimeout(function () {
              this.cancelAction(e);
            }.bind(this), 500);
          }.bind(this))
          .fail(function (errors) { // show errors otherwise
            this.ui.uploadButton.removeClass('disabled');
            this.ui.feedback.html('');
            this.showErrors(errors);
          }.bind(this));
      } else {
        e.preventDefault();
      }
    },
    showErrors: function (errors) {
      var listView = new Team.ImportTagsModalErrorListView({
        collection: new Backbone.Collection(errors),
      });
      this.errors.show(listView);
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.UserCodeHistoryModalView = Marionette.LayoutView.extend({
    template: '#teamUserCodeHistoryModalTemplate',
    className: 'modalWrapper',
    regions: {
      userCodes: '[data-region="userCodes"]',
    },
    ui: {
      modalEL: '.tamModalStructure',
      cancelButton: '[data-action="modalCancel"]',

      showNewCodeButton: '[data-action="showNewCodeButton"]',
      hideNewCodeButton: '[data-action="hideNewCodeButton"]',
      saveNewCodeButton: '[data-action="saveNewCodeButton"]',
      saveNewPipedriveCodeButton: '[data-action="saveNewPipedriveCodeButton"]',

      dateField: '[data-region="dateField"]',

      newUserCode: '#teamNewUserCodeRow',
      newUserPipedriveCode: '#teamNewUserCodePipedriveRow',

      tableHeader: '#teamUserCodeHeader',
      tableHeaderPipedrive: '#teamUserCodePipedriveHeader',

      switchButton: '[data-region="switchButton"]',
      typeZucchetti: '[data-action="typeZucchetti"]',
      typePipedrive: '[data-action="typePipedrive"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',

      'click @ui.showNewCodeButton': 'showNewCode',
      'click @ui.hideNewCodeButton': 'hideNewCode',
      'click @ui.saveNewCodeButton': 'saveNewCode',
      'click @ui.saveNewPipedriveCodeButton': 'saveNewPipedriveCode',
      'click @ui.typeZucchetti': 'filterZucchettiCode',
      'click @ui.typePipedrive': 'filterPipedriveCode',
    },
    initialize: function (options) {
      this.options = options;
      this.userCodeCollection = options.userCodesCollection;

      var userName = this.model.get('person').name + ' ' + this.model.get('person').surname;

      this.model.set('userName', userName);
      this.defaultDate = moment().format('DD-MM-YYYY');

      this.model.set('isPipedriveEnabled', options.isPipedriveIntegrationEnabled);
      this.model.set('filter', 'default');

      if (options.isPipedriveIntegrationEnabled) {
        // Default filter always set to zucchetti
        this.resetCode(this.filterCode('zucchetti'));
      }
    },
    onRender: function () {
      this.placeModal();

      $(this.ui.dateField).datepicker({
        constrainInput: true,
        firstDay: 1,
        dateFormat: 'dd-mm-yy',
        altFormat: 'dd-mm-yy',
        defaultDate: this.defaultDate,
      });
    },
    placeModal: function () {
      var userCodeModalW = 800;
      var userCodeModalH = 600;
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - userCodeModalW) / 2;
      var posTop = (contextH - userCodeModalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    resetCode: function (userCodeList) {
      Team.filterUserCode(userCodeList);
    },
    filterCode: function (type) {
      return this.userCodeCollection.where({ type: type });
    },
    filterZucchettiCode: function (e) {
      e.preventDefault();

      this.model.set('filter', 'default');

      this.ui.typePipedrive.removeClass('sel');
      this.ui.typeZucchetti.addClass('sel');

      this.hideNewCode();

      this.resetCode(this.filterCode('zucchetti'));
      this.showHeader();
    },
    filterPipedriveCode: function (e) {
      e.preventDefault();

      this.model.set('filter', 'pipedrive');

      this.ui.typeZucchetti.removeClass('sel');
      this.ui.typePipedrive.addClass('sel');

      this.hideNewCode();

      var userCodeList = this.filterCode('pipedrive-pm');
      userCodeList = userCodeList.concat(this.filterCode('pipedrive-as'));

      this.resetCode(userCodeList);
      this.showPipedriveHeader();
    },
    showPipedriveHeader: function () {
      this.ui.tableHeaderPipedrive.fadeIn(0);
      this.ui.tableHeader.fadeOut(0);
    },
    showHeader: function () {
      this.ui.tableHeader.fadeIn(0);
      this.ui.tableHeaderPipedrive.fadeOut(0);
    },
    showNewCode: function () {
      if (this.model.get('filter') === 'pipedrive') {
        this.ui.newUserPipedriveCode.fadeIn(0);
      } else {
        this.ui.newUserCode.fadeIn(0);
      }
    },
    hideNewCode: function () {
      this.ui.newUserCode.fadeOut(0);
      this.ui.newUserPipedriveCode.fadeOut(0);
      this.resetNewCode();
    },
    resetNewCode: function () {
      this.ui.newUserCode.find('input[name="newUserCodeCode"]').val('');
      this.ui.newUserCode.find('input[name="newUserCodeFrom"]').val('');
      this.ui.newUserCode.find('input[name="newUserCodeTo"]').val('');
      this.ui.newUserCode.find('input[name="newUserCodeIncludeExport"]').removeAttr('checked');

      this.ui.newUserPipedriveCode.find('input[name="newUserPipedriveCode"]').val('');
      this.ui.newUserPipedriveCode.find('select[name="newUserPipedriveType"]').val('pipedrive-pm');

      this.hideError();
      this.hideErrorPipedrive();
      this.enableSave();
    },
    formatDate: function (inputDate) {
      if (!inputDate) {
        return null;
      }
      var formatted = moment(inputDate, 'DD-MM-YYYY');
      if (!formatted.isValid()) {
        return null;
      }
      return formatted.format('YYYY-MM-DD');
    },
    // Check whether the start date is before the end date given
    isValidDateInterval: function (from, to) {
      if (!from || !to) {
        return true;
      }
      var start = moment(from);
      var end = moment(to);
      return start.isBefore(end, 'day');
    },
    saveNewCode: function () {
      var code = this.ui.newUserCode.find('input[name="newUserCodeCode"]').val().trim();
      var from = this.ui.newUserCode.find('input[name="newUserCodeFrom"]').val().trim();
      var to = this.ui.newUserCode.find('input[name="newUserCodeTo"]').val().trim();
      var includeInExport = this.ui.newUserCode.find('input[name="newUserCodeIncludeExport"]').is(':checked');

      from = this.formatDate(from);
      to = this.formatDate(to);

      if (!code) {
        this.showError('You must insert all required fields');
      } else if (!this.isValidDateInterval(from, to)) {
        this.showError('End date must come after the start date');
      } else {
        this.hideError();

        var data = {
          external_id: code,
          include_in_export: includeInExport,
          type: 'zucchetti',
        };

        if (from) {
          data.start_date = from;
        }
        if (to) {
          data.end_date = to;
        }

        this.disableSave();

        Team.saveUserCode(data, this.options.employeeView, this);
      }
    },
    saveNewPipedriveCode: function () {
      var code = this.ui.newUserPipedriveCode.find('input[name="newUserPipedriveCode"]').val().trim();
      var type = this.ui.newUserPipedriveCode.find('select[name="newUserPipedriveType"]').val().trim();

      if (!code || !type) {
        this.showErrorPipedrive('You must insert all required fields');
      } else {
        var isDuplicated = this.isCodeTypeDuplicated(type);

        if (isDuplicated) {
          this.showErrorPipedrive('This employee already has a code of this type.');
        } else {
          this.hideErrorPipedrive();

          var data = {
            external_id: code,
            include_in_export: false,
            type: type,
          };

          this.disableSave();

          Team.saveUserCode(data, this.options.employeeView, this);
        }
      }
    },
    isCodeTypeDuplicated: function (type) {
      var listOfCodesByType = this.filterCode(type);

      return listOfCodesByType && listOfCodesByType.length > 0;
    },
    showError: function (error) {
      this.$el.find('#teamNewUserCodeRow')
        .find('#newLabelErrorBox').remove();
      this.$el.find('#teamNewUserCodeRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideError: function () {
      this.$el.find('#teamNewUserCodeRow').find('#newLabelErrorBox').remove();
    },
    showErrorPipedrive: function (error) {
      this.$el.find('#teamNewUserCodePipedriveRow')
        .find('#newLabelErrorBox').remove();
      this.$el.find('#teamNewUserCodePipedriveRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideErrorPipedrive: function () {
      this.$el.find('#teamNewUserCodePipedriveRow').find('#newLabelErrorBox').remove();
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
    enableSave: function () {
      if (this.ui.saveNewCodeButton.hasClass('disabled')) {
        this.ui.saveNewCodeButton.removeClass('disabled');
      }
    },
    disableSave: function () {
      if (!this.ui.saveNewCodeButton.hasClass('disabled')) {
        this.ui.saveNewCodeButton.addClass('disabled');
      }
    },
  });

  this.UserCodeItemView = Marionette.ItemView.extend({
    defaultTemplate: '#teamUserCodeCollectionTemplate',
    pipedriveTemplate: '#teamUserCodePipedriveCollectionTemplate',
    template: function (serializedModel) {
      var that = this;

      var template = $(that.defaultTemplate).html();

      if (serializedModel.type.includes('pipedrive')) {
        template = $(that.pipedriveTemplate).html();
      }

      return _.template(template)(serializedModel);
    },
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
    ui: {
      userCode: '[data-action="userCode"]',
      userCodeFrom: '[data-action="userCodeFrom"]',
      userCodeTo: '[data-action="userCodeTo"]',
      userCodeIncludeExport: '[data-action="userCodeIncludeExport"]',
      userCodeType: '[data-action="userCodeType"]',

      dateFieldFrom: '[data-region="dateFieldFrom"]',
      dateFieldTo: '[data-region="dateFieldTo"]',

      deleteUserCode: '[data-action="deleteUserCode"]',
    },
    events: {
      'change @ui.userCode': 'onUserCodeChange',
      'change @ui.userCodeFrom': 'onUserCodeFromChange',
      'change @ui.userCodeTo': 'onUserCodeToChange',
      'change @ui.userCodeIncludeExport': 'onUserCodeIncludeExportChange',
      'change @ui.userCodeType': 'onUserCodeTypeChange',

      'click @ui.deleteUserCode': 'deleteUserCode',
    },
    initialize: function (options) {
      $(this.$el).attr('data-id', this.model.get('id'));
      this.defaultDate = moment().format('DD-MM-YYYY');
      this.employeeView = options.employeeView;

      _.bindAll(this, 'template');
    },
    onRender: function () {
      // Date picker input from
      $(this.ui.dateFieldFrom).datepicker({
        constrainInput: true,
        firstDay: 1,
        dateFormat: 'dd-mm-yy',
        altFormat: 'dd-mm-yy',
        defaultDate: this.defaultDate,
        onSelect: function (dateText) {
          var formatted = this.formatDate(dateText);
          this.model.set('start_date', formatted);

          this.updateUserCode();
        }.bind(this),
      });
      // Date picker input to
      $(this.ui.dateFieldTo).datepicker({
        constrainInput: true,
        firstDay: 1,
        dateFormat: 'dd-mm-yy',
        altFormat: 'dd-mm-yy',
        defaultDate: this.defaultDate,
        onSelect: function (dateText) {
          var formatted = this.formatDate(dateText);
          this.model.set('end_date', formatted);

          this.updateUserCode();
        }.bind(this),
      });
    },
    formatDate: function (inputDate) {
      var formatted = moment(inputDate, 'DD-MM-YYYY');
      if (!formatted.isValid()) {
        formatted = moment();
      }
      return formatted.format('YYYY-MM-DD');
    },
    onUserCodeChange: function () {
      var code = this.ui.userCode.val().trim();

      var changes = { external_id: code };

      this.model.set(changes);
      this.updateUserCode();
    },
    // Check whether the start date is before the end date given
    isValidDateInterval: function (from, to) {
      if (!from || !to) {
        return true;
      }
      var start = moment(from);
      var end = moment(to);
      return start.isBefore(end, 'day');
    },
    onUserCodeFromChange: function () {
      var from = this.ui.userCodeFrom.val().trim();
      var formatted = from ? this.formatDate(from) : null;

      var changes = { start_date: formatted };

      this.model.set(changes);
      this.updateUserCode();
    },
    onUserCodeToChange: function () {
      var to = this.ui.userCodeTo.val().trim();
      var formatted = to ? this.formatDate(to) : null;

      var changes = { end_date: formatted };

      this.model.set(changes);
      this.updateUserCode();
    },
    onUserCodeIncludeExportChange: function () {
      var includeExport = this.ui.userCodeIncludeExport.is(':checked');

      var changes = { include_in_export: includeExport };

      this.model.set(changes);
      this.updateUserCode();
    },
    onUserCodeTypeChange: function () {
      var type = this.ui.userCodeType.val().trim();

      var changes = { type: type };

      this.model.set(changes);
      this.updateUserCode();
    },
    isValid: function () {
      var model = this.model.toJSON();

      if (!model.external_id) {
        this.showMissingFieldError();
        return false;
      }
      if (!this.isValidDateInterval(model.start_date, model.end_date)) {
        this.showDateIntervalError();
        return false;
      }
      this.hideError();
      return true;
    },
    updateUserCode: function () {
      if (this.isValid()) {
        Team.updateUserCode(this.model, this.employeeView, this);
      }
    },
    deleteUserCode: function () {
      Team.deleteUserCode(this.model, this.employeeView, this);
    },
    showMissingFieldError: function () {
      this.showError('You must insert all required fields');
    },
    showDateIntervalError: function () {
      this.showError('End date must come after start date');
    },
    showError: function (error) {
      this.$el.find('#newLabelErrorBox').remove();
      this.$el.append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideError: function () {
      this.$el.find('#newLabelErrorBox').remove();
    },
  });

  this.UserCodeEmptyItemView = Marionette.ItemView.extend({
    template: '#teamUserCodeEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
  });

  this.UserCodeCollectionView = Marionette.CollectionView.extend({
    childView: Team.UserCodeItemView,
    emptyView: Team.UserCodeEmptyItemView,
    className: 'teamUserCodeCollection',
    initialize: function (options) {
      this.childViewOptions = {
        employeeView: options.employeeView,
      };
    },
  });
  this.AutocompleteLoadingTemplateView = Marionette.ItemView.extend({
    template: '#reportDetailAutocompleteLoadingTemplate',
  });

  this.AutocompleteNotFoundTemplateView = Marionette.ItemView.extend({
    template: '#reportDetailAutocompleteNotFoundTemplate',
  });
  this.AutocompleteRecordItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#reportDetailAutocompleteTemplate',
    ui: {
      recordEL: '[data-action="selectRecord"]',
    },
    events: {
      'click @ui.recordEL': 'selectRecord',
    },
    initialize: function (options) {
      this.options = options;
    },
    selectRecord: function (e) {
      e.preventDefault();
      var that = this;

      this.options.parent.ui.userInputEl.parents('.mdl-textfield').addClass('is-dirty is-focused');
      this.options.parent.ui.userInputEl.val(that.model.get('name') + ' ' + that.model.get('surname'));

      this.options.parent.model.set({
        allocation_manager: {
          id: that.model.get('employee_id'),
          name: that.model.get('name'),
          surname: that.model.get('surname'),
        },
      });
      this.options.parent.ui.autocompleteArea.removeClass('isVisible');

      this.options.parent.ui.actionButton.removeClass('disabled');
    },
  });
  this.AutocompleteRecordsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'budgetAutocompleteList',
    childView: Team.AutocompleteRecordItemView,
  });
  this.AllocationManagerModalView = Marionette.LayoutView.extend({
    template: '#allocationManagerModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.modalStructure',
      cancelButton: '[data-action="modalCancel"]',
      actionButton: '[data-action="modalDoAction"]',
      mdlInput: '[data-region="mdl-input"]',
      autocompleteArea: '[data-region="autocompleteArea"]',
      textEl: '[data-action="modalMessage"]',
      statusEl: '[data-message="statusModalAction"]',
      userInputEl: '[data-action="autocompleteManagerLink"]',
    },
    events: {
      'click @ui.userInputEl': 'clickSearchUser',
      'keyup @ui.userInputEl': 'searchUser',
      'click @ui.cancelButton': 'doKoAction',
      'click @ui.actionButton': 'doOkAction',
      'click @ui.modalEL': 'closeHint',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.placeModal();

      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });

      this.ui.actionButton.addClass('disabled');

      var allocationManager = this.model.get('allocation_manager');
      var name = allocationManager ? allocationManager.name + ' ' + allocationManager.surname : '';
      this.ui.userInputEl.val(name);
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      if ($(window).width() > 600) {
        this.ui.modalEL.css({
          left: posLeft,
          top: posTop,
        }).show();
      } else {
        this.ui.modalEL.css({
          left: 0,
          top: posTop,
        }).show();
      }
    },
    closeHint: function () {
      this.ui.autocompleteArea.removeClass('isVisible');
    },
    autocomplete: function (keyword) {
      var foundEmployees = this.options.employees.filter(function (employee) {
        var fullName = employee.get('person').name + ' ' + employee.get('person').surname;
        return fullName.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
      }).map(function (employee) {
        return Object.assign(employee.get('person'), { employee_id: employee.get('employee').id });
      });
      var template = new Team.AutocompleteNotFoundTemplateView();
      if (foundEmployees.length) {
        template = new Team.AutocompleteRecordsCollectionView({
          collection: new Backbone.Collection(foundEmployees),
          childViewOptions: {
            parent: this,
          },
        });
      }

      this.ui.autocompleteArea.html(template.render().$el);
      this.ui.autocompleteArea.addClass('isVisible');
    },
    clickSearchUser: function (e) {
      e.stopPropagation();
      var target = $(e.target);

      var value = String(_.escape(target.val().trim()));

      if (value === '') {
        this.autocomplete('');
      }
      Wethod.onHtmlClick(this.ui.autocompleteArea.selector, 'isVisible', 'remove');
    },
    allowTextChar: function (charCode) {
      return (charCode >= 46 && charCode <= 90) || (charCode >= 96 && charCode <= 111)
        || _.contains([8, 9, 187, 189], charCode);
    },
    searchUser: function (e) {
      var target = $(e.target);
      var keyCode = e.keyCode;
      var value = String(_.escape(target.val().trim()));

      if (value === '') {
        this.model.set({ allocation_manager: null });
        this.ui.autocompleteArea.removeClass('isVisible');
        this.ui.actionButton.removeClass('disabled');
      } else if (this.allowTextChar(keyCode)) {
        this.ui.actionButton.addClass('disabled');
        this.autocomplete(value);
      }
      Wethod.onHtmlClick(this.ui.autocompleteArea.selector, 'isVisible', 'remove');
    },
    setManager: function (allocationManager) {
      var params = {
        allocation_manager_id: allocationManager ? allocationManager.id : null,
        employee_id: this.model.get('id'),
      };
      var request = Wethod.request('team:allocation:manager:update', params);
      $.when(request).done(function () {
        var employee = Object.assign(this.options.viewObj.model.get('employee'), { allocation_manager: allocationManager });
        this.options.viewObj.model.set('employee', employee);
        var name = allocationManager ? allocationManager.name + ' ' + allocationManager.surname : '';
        this.options.viewObj.renderAllocationManager(name);
        this.destroy();
      }.bind(this));
    },
    doOkAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.cancelButton.addClass('disabled');
        this.ui.actionButton.addClass('disabled');
        this.ui.statusEl.text('Saving...');
        this.setManager(this.model.get('allocation_manager'));
      }
    },
    doKoAction: function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.ui.cancelButton.hasClass('disabled')) {
        this.destroy();
      }
    },
  });
  this.PricelistModalAutocompleteRecordItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: '#pricelistModalAutocompleteTemplate',
    ui: {
      recordEL: '[data-action="selectRecord"]',
    },
    events: {
      'click @ui.recordEL': 'selectRecord',
    },
    initialize: function (options) {
      this.options = options;
    },
    selectRecord: function (e) {
      e.preventDefault();
      var that = this;

      this.options.parent.ui.userInputEl.parents('.mdl-textfield').addClass('is-dirty is-focused');
      this.options.parent.ui.userInputEl.val(that.model.get('name'));

      this.options.parent.model.set({
        pricelist: {
          id: that.model.get('id'),
          name: that.model.get('name'),
          available_from: this.model.get('available_from'),
          available_to: this.model.get('available_to'),
        },
      });
      this.options.parent.ui.autocompleteArea.removeClass('isVisible');

      this.options.parent.ui.actionButton.removeClass('disabled');
    },
  });
  this.PricelistModalAutocompleteRecordsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'budgetAutocompleteList',
    childView: Team.PricelistModalAutocompleteRecordItemView,
  });
  this.PricelistModalView = Marionette.LayoutView.extend({
    template: '#setPricelistModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.modalStructure',
      cancelButton: '[data-action="modalCancel"]',
      actionButton: '[data-action="modalDoAction"]',
      mdlInput: '[data-region="mdl-input"]',
      autocompleteArea: '[data-region="autocompleteArea"]',
      textEl: '[data-action="modalMessage"]',
      statusEl: '[data-message="statusModalAction"]',
      userInputEl: '[data-action="autocompletePricelistLink"]',
    },
    events: {
      'click @ui.userInputEl': 'clickSearchPricelist',
      'keyup @ui.userInputEl': 'searchPricelist',
      'click @ui.cancelButton': 'doKoAction',
      'click @ui.actionButton': 'doOkAction',
      'click @ui.modalEL': 'closeHint',
    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.placeModal();

      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });

      this.ui.actionButton.addClass('disabled');

      this.ui.userInputEl.val(this.model.get('pricelist').name);
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      if ($(window).width() > 600) {
        this.ui.modalEL.css({
          left: posLeft,
          top: posTop,
        }).show();
      } else {
        this.ui.modalEL.css({
          left: 0,
          top: posTop,
        }).show();
      }
    },
    closeHint: function () {
      this.ui.autocompleteArea.removeClass('isVisible');
    },
    autocomplete: function (keyword) {
      var loadingTemplate = new Team.AutocompleteLoadingTemplateView();
      this.ui.autocompleteArea.html(loadingTemplate.render().$el);
      this.ui.autocompleteArea.addClass('isVisible');
      var foundPricelists = [];
      var filters = {
        search: keyword,
        available: true,
      };
      PricelistModel.list(filters).done(function (list) {
        foundPricelists = list;
        var template = new Team.AutocompleteNotFoundTemplateView();
        if (foundPricelists.length) {
          template = new Team.PricelistModalAutocompleteRecordsCollectionView({
            collection: new Backbone.Collection(foundPricelists),
            childViewOptions: {
              parent: this,
            },
          });
        }

        this.ui.autocompleteArea.html(template.render().$el);
        this.ui.autocompleteArea.addClass('isVisible');
      }.bind(this));
    },
    clickSearchPricelist: function (e) {
      e.stopPropagation();
      var target = $(e.target);

      var value = String(_.escape(target.val().trim()));

      if (value === '') {
        this.autocomplete('');
      }
      Wethod.onHtmlClick(this.ui.autocompleteArea.selector, 'isVisible', 'remove');
    },
    allowTextChar: function (charCode) {
      return (charCode >= 46 && charCode <= 90) || (charCode >= 96 && charCode <= 111)
        || _.contains([8, 9, 187, 189], charCode);
    },
    searchPricelist: function (e) {
      var target = $(e.target);
      var keyCode = e.keyCode;
      var value = String(_.escape(target.val().trim()));

      if (this.allowTextChar(keyCode)) {
        this.ui.actionButton.addClass('disabled');
        this.autocomplete(value);
      }
      Wethod.onHtmlClick(this.ui.autocompleteArea.selector, 'isVisible', 'remove');
    },
    setPricelist: function (pricelist) {
      var params = {
        pricelistId: pricelist.id,
        levelId: this.model.get('level').id,
        employeeId: this.model.get('employee').id,
      };
      var request = Wethod.request('team:employee:pricelist:level:update', params);
      $.when(request).done(function () {
        var employee = Object.assign(this.options.viewObj.model.get('price_list'), {
          id: pricelist.id,
          name: pricelist.name,
          available_from: pricelist.available_from,
          available_to: pricelist.available_to,
        });
        this.options.viewObj.model.set('employee', employee);

        this.options.viewObj.onShow();
        this.destroy();
      }.bind(this));
    },
    doOkAction: function (e) {
      e.preventDefault();
      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.cancelButton.addClass('disabled');
        this.ui.actionButton.addClass('disabled');
        this.ui.statusEl.text('Saving...');
        this.setPricelist(this.model.get('pricelist'));
      }
    },
    doKoAction: function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.ui.cancelButton.hasClass('disabled')) {
        this.destroy();
      }
    },
  });

  this.ChangeLogModalView = Marionette.LayoutView.extend({
    template: '#changeLogModalTemplate',
    className: 'modalWrapper',
    regions: {
      changeLogLabel: '[data-region="changeLog"]',
    },
    ui: {
      modalEL: '.modalStructure',
      cancelButton: '[data-action="modalCancel"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',

    },
    initialize: function (options) {
      this.options = options;
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      if ($(window).width() > 600) {
        this.ui.modalEL.css({
          left: posLeft,
          top: posTop,
        }).show();
      } else {
        this.ui.modalEL.css({
          left: 0,
          top: posTop,
        }).show();
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });
});
