const React = require('react');
const PropTypes = require('prop-types');
const T = require('../../Typography/Typography.react');

/**
 * @param {string} color
 * @param {string} label
 * @return {JSX.Element}
 * @constructor
 */
const ChartLegendItem = ({ label, color = '' }) => {
  const getColorStyle = () => ({
    backgroundColor: color,
  });

  return (
    <li className="wethod-chart-legend-item">
      <span className="wethod-chart-legend-item-color" style={getColorStyle()} data-testid="chart-legend-item-color" />
      <span className="wethod-chart-legend-item-name">
        <T size={T.SIZES.PX11}>{label}</T>
      </span>
    </li>
  );
};

ChartLegendItem.defaultProps = {
  color: '',
};

ChartLegendItem.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
};

module.exports = ChartLegendItem;
