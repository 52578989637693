class ChartConfigClientDimensionGrouping {
  /**
   * @param {string} type
   * @param {number} value
   */
  constructor(type, value) {
    this.type = type;
    this.value = value;
  }

  /**
   * @return {boolean}
   */
  isGrouped() {
    return this.type !== ChartConfigClientDimensionGrouping.TYPE_DISTINCT;
  }

  /**
   * Return index of the group containing "all the rest" clients.
   * @return {?number}
   */
  getOthersGroupIndex() {
    return this.isGrouped() ? this.value : null;
  }
}

ChartConfigClientDimensionGrouping.TYPE_DISTINCT = 'distinct';
ChartConfigClientDimensionGrouping.TYPE_TOP_N = 'top-n';

module.exports = ChartConfigClientDimensionGrouping;
