const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterClient extends ChartConfigFilter {
  constructor(type) {
    super(ChartConfigFilterClient.TARGET, type);
  }
}

ChartConfigFilterClient.TARGET = 'client';

module.exports = ChartConfigFilterClient;
