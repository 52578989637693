const ChartConfigDimension = require('./ChartConfigDimension');

class ChartConfigProbabilityDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigProbabilityDimensionGrouping} grouping
   */
  constructor(grouping) {
    super(ChartConfigProbabilityDimension.KEY);
    this.grouping = grouping;
  }

  /**
   * Return cluster with given name.
   * @param {string} name
   * @return {ChartConfigProbabilityDimensionGroupingValue}
   */
  findCluster(name) {
    return this.grouping.findItem(name);
  }
}

ChartConfigProbabilityDimension.KEY = 'probability';

module.exports = ChartConfigProbabilityDimension;
