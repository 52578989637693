const React = require('react');
const T = require('../../../Typography/Typography.react');

/**
 * Used to tell user that a section is new.
 * @return {JSX.Element}
 * @constructor
 */
const NewSectionLabel = () => (
  <span className="app-nav__section-label--new" aria-label="new">
    <T size={T.SIZES.PX10} color={T.COLORS.WHITE} uppercase>new</T>
  </span>
);

module.exports = NewSectionLabel;
