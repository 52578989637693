const constants = require('./constants');
const BudgetInfoService = require('./services/BudgetInfoService');
const VersionService = require('./services/VersionService');

class BudgetReducer {
  constructor(state) {
    this.state = {
      permissions: null,
      user: null,
      project: null,
      targetVersion: null,
      // Companies belonging to the same group and available as intercompany suppliers
      intercompanySuppliers: [],
      projectStatusPermissions: null,
      info: null,
      // List of budget versions
      versions: [],
      // List of budget areas
      areas: [],
      projectStatusCorrection: null,
      modalToShow: null,
      modalData: null,
      compareMode: false,
      // List of request to complete
      waitingFor: [],
      availableApprovers: [],
      // Map of retrieved intercompany info per task
      intercompanyTaskInfoMap: {},
      showSidebar: false,
      // Company currencies available to the current budget
      currencies: [],
      // Budget logs
      logs: [],
      scrollingComponentAmount: 0,
      collapsedAreas: [],
      priceListLevel: [],
      currentPriceListLevel: [],
      ...state,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  /**
   * Return list of request to complete augmented with given key.
   * @param state
   * @param key
   * @returns {*}
   */
  static addWaitingFor(state, key) {
    return state.waitingFor.concat(key);
  }

  /**
   * Return list of request to complete without given key.
   * @param state
   * @param key
   * @returns {*}
   */
  static removeWaitingFor(state, key) {
    return state.waitingFor.filter((waitingKey) => waitingKey !== key);
  }

  /**
   * Return if there is a pending request with given key.
   * @param state
   * @param key
   * @returns {boolean}
   */
  static waitingFor(state, key) {
    return state.waitingFor.indexOf(key) !== -1;
  }

  /**
   * Return if currencies are available for company.
   * @param state
   * @returns {boolean}
   */
  static currenciesAvailable(state) {
    return state.currencies.length > 0;
  }

  static getUpdatedTasks(state, taskId, changes) {
    return state.info.tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, ...changes };
      }
      return task;
    });
  }

  static getUpdatedTaskIntercompany(state, taskId, changes) {
    return state.info.tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          intercompany: {
            ...task.intercompany,
            ...changes,
          },
        };
      }
      return task;
    });
  }

  static getAreasWithUpdatedTask(state, areaId, taskId, changes) {
    return state.areas.map((area) => {
      if (area.id === areaId) {
        return {
          ...area,
          tasks: area.tasks.map((areaTask) => {
            if (areaTask.id_task === taskId) {
              return {
                ...areaTask,
                ...changes,
              };
            }
            return areaTask;
          }),
        };
      }
      return area;
    });
  }

  static getAreasWithUpdatedTaskIntercompany(state, areaId, taskId, changes) {
    return state.areas.map((area) => {
      if (area.id === areaId) {
        return {
          ...area,
          tasks: area.tasks.map((areaTask) => {
            if (areaTask.id_task === taskId) {
              return {
                ...areaTask,
                intercompany: {
                  ...areaTask.intercompany,
                  ...changes,
                },
              };
            }
            return areaTask;
          }),
        };
      }
      return area;
    });
  }

  static getAreasWithUpdatedTaskOrder(state, areaId, tasks) {
    return state.areas.map((area) => {
      if (area.id === areaId) {
        return {
          ...area,
          tasks: area.tasks.map((areaTask) => {
            const updatedTask = tasks.find((task) => parseInt(task.id_task) === areaTask.id_task);

            return {
              ...areaTask,
              order: updatedTask.order,
            };
          }),
        };
      }
      return area;
    });
  }

  static getUpdatedDays(state, dayId, changes) {
    return state.info.days.map((day) => {
      if (day.id === dayId) {
        return { ...day, ...changes };
      }
      return day;
    });
  }

  static getAreasWithUpdatedTaskLevel(state, areaId, taskId, levelId, quantity) {
    return state.areas.map((area) => {
      if (area.id === areaId) {
        return {
          ...area,
          tasks: area.tasks.map((areaTask) => {
            if (areaTask.id_task === taskId) {
              return {
                ...areaTask,
                levels: areaTask.levels.map((level) => {
                  if (level.id_level === levelId) {
                    return {
                      ...level,
                      quantity,
                    };
                  }
                  return level;
                }),
              };
            }
            return areaTask;
          }),
        };
      }
      return area;
    });
  }

  static getAreasWithAddedTask(state, areaId, task) {
    return state.areas.map((area) => {
      if (area.id === areaId) {
        return {
          ...area,
          tasks: area.tasks.concat(task),
        };
      }
      return area;
    });
  }

  static getAreasWithDeletedTask(state, areaId, id) {
    return state.areas.map((area) => {
      if (area.id === areaId) {
        return {
          ...area,
          tasks: area.tasks.filter((task) => task.id_task !== id),
        };
      }
      return area;
    });
  }

  static getUpdatedInfo(state, info) {
    return {
      ...state.info,
      ...info,
    };
  }

  static getUpdatedAreas(state, updatedArea) {
    return state.info.areas.map((area) => {
      if (area.id === updatedArea.id) {
        return { ...area, ...updatedArea };
      }
      return area;
    });
  }

  /**
   * Return given tasks with "sort" attribute updated as expressed in "positions" array.
   * @param tasks
   * @param {[{id:integer,sort:number}]} positions
   * @return {*}
   */
  static getTasksWithUpdatedSort(tasks, positions) {
    return tasks.map((task) => {
      const positionToUpdate = positions
        .find((position) => position.id === task.id);
      if (positionToUpdate) {
        return {
          ...task,
          sort: positionToUpdate.sort,
        };
      }
      return task;
    });
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      case constants.SHOW_MODAL:
        return {
          ...state,
          modalToShow: action.key,
          modalData: action.data,
        };
      case constants.CLOSE_SIDEBAR:
        return {
          ...state,
          showSidebar: false,
        };
      case constants.SHOW_SIDEBAR:
        return {
          ...state,
          showSidebar: true,
        };
      case constants.SHOW_SIDEBAR_PROJECT:
        return {
          ...state,
        };
      case constants.COMPARE_VERSIONS_MODE_ENTER:
        return {
          ...state,
          compareMode: true,
        };
      case constants.COMPARE_VERSIONS_MODE_EXIT:
        return {
          ...state,
          compareMode: false,
        };
      case constants.SAVE_TEMPLATE_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'save-template'),
        };
      case constants.SAVE_TEMPLATE_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'save-template'),
          modalToShow: null,
        };
      case constants.SAVE_TEMPLATE_FAILURE:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'save-template'),
        };
      case constants.UPDATE_INFO_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'save-budget-info'),
          // optimistic update needs state to be updated on request
          info: !action.optimistic ? state.info : BudgetReducer
            .getUpdatedInfo(state, action.updatedBudgetInfo),
        };
      case constants.COLLABORATOR_UPDATE_INFO:
        return {
          ...state,
          info: BudgetReducer.getUpdatedInfo(state, action.updatedBudgetInfo),
        };
      case constants.UPDATE_INFO_SUCCESS:
        return {
          ...state,
          modalToShow: null,
          // optimistic update needs has state to updated on request
          info: action.optimistic ? state.info : BudgetReducer
            .getUpdatedInfo(state, action.updatedBudgetInfo),
          waitingFor: BudgetReducer.removeWaitingFor(state, 'save-budget-info'),
        };
      case constants.UPDATE_INFO_FAILURE:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'save-budget-info'),
        };
      case constants.UPDATE_INFO_TOTAL:
        return {
          ...state,
          info: {
            ...state.info,
            total_external_cost: action.externalCosts,
            total_cost: action.cost,
            total_price: action.price,
            total_days: action.days,
          },
        };
      case constants.APPLY_PS_CORRECTION_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'apply-ps-correction'),
        };
      case constants.APPLY_PS_CORRECTION_SUCCESS:
        return {
          ...state,
          projectStatusCorrection: null,
          modalToShow: null,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'apply-ps-correction'),
        };
      case constants.GET_CONSUMPTION_REPORT_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'project-report'),
        };
      case constants.GET_CONSUMPTION_REPORT_SUCCESS:
        return {
          ...state,
          projectReportData: action.data,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'project-report'),
        };
      case constants.GET_APPROVERS_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'get-approvers'),
        };
      case constants.GET_APPROVERS_SUCCESS:
        return {
          ...state,
          availableApprovers: action.approvers,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'get-approvers'),
        };
      case constants.GET_APPROVERS_FAILURE:
        return {
          ...state,
          availableApprovers: [],
          waitingFor: BudgetReducer.removeWaitingFor(state, 'get-approvers'),
        };
      case constants.CHANGE_STATUS_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'change-status'),
        };
      case constants.CHANGE_STATUS_SUCCESS:
        return {
          ...state,
          modalToShow: null,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'change-status'),
        };
      case constants.GET_VERSION_LIST_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'get-version-list'),
        };
      case constants.GET_VERSION_LIST_FAILURE:
      case constants.GET_VERSION_LIST_SUCCESS:
        return {
          ...state,
          versions: action.versions,
          baselines: action.baselines,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'get-version-list'),
        };
      case constants.GET_VERSION_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'get-budget-version'),
        };
      case constants.GET_VERSION_FAILURE:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'get-budget-version'),
        };
      case constants.GET_VERSION_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'get-budget-version'),
          info: action.info,
          targetVersion: action.version,
          currencies: action.currencies,
          logs: action.logs,
        };
      case constants.GET_VERSION_COMPARISON_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'get-version-comparison'),
        };
      case constants.GET_VERSION_COMPARISON_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'get-version-comparison'),
          info: {
            ...VersionService.formatWithWas(
              BudgetInfoService.formatBudgetInfoVersion(
                action.info,
              ),
            ),
          },
          areas: null,
        };
      case constants.VERSION_COMPARATOR_CLEAR:
        return {
          ...state,
          info: {
            ...state.info,
            was: undefined,
            areas: state.info.areas.map((area) => ({
              ...area, was: undefined, is_new: undefined, deleted: false,
            })),
            tasks: state.info.tasks.map((task) => ({
              ...task, was: undefined, is_new: undefined, deleted: false,
            })),
            days: state.info.days.map((day) => ({
              ...day, was: undefined, is_new: undefined, deleted: false,
            })),
            levels: state.info.levels.map((level) => ({
              ...level,
              was: undefined,
              is_new: undefined,
              deleted: false,
            })),
          },
        };
      case constants.COLLABORATOR_UPDATE_TASK_INFO:
        return {
          ...state,
          info: {
            ...state.info,
            tasks: BudgetReducer.getUpdatedTasks(state, action.taskInfo.task.id,
              action.taskInfo.task),
          },
        };
      case constants.UPDATE_TASK_INFO_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, `save-task-${action.taskId}}`),
          info: {
            ...state.info,
            tasks: BudgetReducer.getUpdatedTasks(state, action.taskId, action.taskInfo),
          },
        };
      case constants.UPDATE_TASK_INFO_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, `save-task-${action.task.id}`),
        };
      case constants.COLLABORATOR_UPDATE_TASK_LEVEL_AMOUNT:
        return {
          ...state,
          info: {
            ...state.info,
            days: BudgetReducer.getUpdatedDays(state, action.info.id, { days: action.info.days }),
          },
        };
      case constants.UPDATE_TASK_LEVEL_AMOUNT_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, `save-task-${action.levelId}`),
          info: {
            ...state.info,
            days: BudgetReducer.getUpdatedDays(state, action.levelId, { days: action.quantity }),
          },
        };
      case constants.UPDATE_TASK_LEVEL_AMOUNT_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, `save-task-${action.day.id}`),
        };
      case constants.COLLABORATOR_UPDATE_TASK_VALUES:
        return {
          ...state,
          areas: BudgetReducer.getAreasWithUpdatedTask(state, action.info.areaId,
            action.info.taskId, action.info.values),
        };
      case constants.UPDATE_TASK_VALUES_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, `save-task-${action.taskId}`),
          areas: BudgetReducer.getAreasWithUpdatedTask(state, action.areaId,
            action.taskId, action.values),
        };
      case constants.UPDATE_TASK_VALUES_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, `save-task-${action.taskId}`),
        };
      case constants.CREATE_TASK_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, `add-task-to-area-${action.areaId}`),
        };
      case constants.CREATE_TASK_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, `add-task-to-area-${action.areaId}`),
          info: {
            ...state.info,
            tasks: state.info.tasks.concat(action.task),
            days: state.info.days.concat(action.days),
          },
        };
      case constants.COLLABORATOR_CREATE_TASK:
        return {
          ...state,
          info: {
            ...state.info,
            tasks: state.info.tasks.concat(action.info.tasks),
            days: state.info.days.concat(action.info.days),
          },
        };
      case constants.CREATE_AREA_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'add-area'),
        };
      case constants.CREATE_AREA_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'add-area'),
          info: {
            ...state.info,
            areas: state.info.areas.concat(action.area),
          },
        };
      case constants.COLLABORATOR_CREATE_AREA:
        return {
          ...state,
          info: {
            ...state.info,
            areas: state.info.areas.concat(action.info.area),
          },
        };
      case constants.UPDATE_TASK_ORDER_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'save-task-order'),
        };
      case constants.UPDATE_TASK_ORDER_SUCCESS: {
        return {
          ...state,
          modalToShow: null,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'save-task-order'),
          info: {
            ...state.info,
            tasks: BudgetReducer.getTasksWithUpdatedSort(state.info.tasks, action.positions),
          },
        };
      }
      case constants.COLLABORATOR_UPDATE_TASK_ORDER: {
        return {
          ...state,
          modalToShow: null,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'save-task-order'),
          info: {
            ...state.info,
            tasks: BudgetReducer.getTasksWithUpdatedSort(state.info.tasks, action.info),
          },
        };
      }
      case constants.COLLABORATOR_DELETE_TASK:
        return {
          ...state,
          info: {
            ...state.info,
            tasks: state.info.tasks.filter((task) => task.id !== action.info.id),
          },
        };
      case constants.DELETE_TASK_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'delete-task'),
        };
      case constants.DELETE_TASK_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'delete-task'),
          info: {
            ...state.info,
            tasks: state.info.tasks.filter((task) => task.id !== action.id),
          },
          modalToShow: null,
          modalData: null,
        };
      case constants.COLLABORATOR_UPDATE_AREA:
        return {
          ...state,
          info: {
            ...state.info,
            areas: BudgetReducer.getUpdatedAreas(state, action.area),
          },
        };
      case constants.UPDATE_AREA_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, `update-area-${action.id}`),
          info: {
            ...state.info,
            areas: BudgetReducer.getUpdatedAreas(state, { id: action.id, ...action.area }),
          },
        };
      case constants.UPDATE_AREA_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, `update-area-${action.id}`),
          info: {
            ...state.info,
            areas: BudgetReducer.getUpdatedAreas(state, action.area),
          },
        };
      case constants.MAKE_TASK_INTERCOMPANY_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'create-intercompany-task'),
        };
      case constants.MAKE_TASK_INTERCOMPANY_SUCCESS: {
        const supplier = state.intercompanySuppliers
          .find((intercompanySupplier) => intercompanySupplier.id === action.supplierId);
        const taskChanges = {
          intercompany: {
            connected: false,
            sent: false,
            supplier,
          },
        };
        return {
          ...state,
          modalToShow: null,
          modalData: null,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'create-intercompany-task'),
          info: {
            ...state.info,
            tasks: BudgetReducer.getUpdatedTasks(state, action.task.id, taskChanges),
          },
        };
      }
      case constants.INVITE_INTERCOMPANY_SUPPLIER_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'invite-intercompany-supplier'),
        };
      case constants.INVITE_INTERCOMPANY_SUPPLIER_SUCCESS: {
        const intercompanyChanges = {
          sent: true,
        };
        return {
          ...state,
          modalToShow: null,
          modalData: null,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'invite-intercompany-supplier'),
          info: {
            ...state.info,
            tasks: BudgetReducer.getUpdatedTaskIntercompany(state, action.taskId,
              intercompanyChanges),
          },
        };
      }
      case constants.GET_INTERCOMPANY_TASK_INFO_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, `get-intercompany-task-info-${action.taskId}`),
        };
      case constants.GET_INTERCOMPANY_TASK_INFO_SUCCESS: {
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, `get-intercompany-task-info-${action.taskId}`),
          intercompanyTaskInfoMap: {
            ...state.intercompanyTaskInfoMap,
            [action.taskId]: action.info,
          },
        };
      }
      case constants.CREATE_BUDGET_REQUEST: {
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'creation'),
          modalToShow: null,
          modalData: null,
        };
      }
      case constants.CREATE_BUDGET_SUCCESS: {
        return {
          ...state,
          info: action.budgetInfo,
          targetVersion: action.budgetInfo.version,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'creation'),
          currencies: action.currencies,
        };
      }
      case constants.GET_PS_CORRECTION_REQUEST: {
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'get-ps-correction'),
        };
      }
      case constants.GET_PS_CORRECTION_SUCCESS: {
        return {
          ...state,
          projectStatusCorrection: action.correction,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'get-ps-correction'),
        };
      }
      case constants.INSERT_JOB_TITLE_REQUEST: {
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'insert-job-title'),
        };
      }
      case constants.INSERT_JOB_TITLE_SUCCESS: {
        return {
          ...state,
          modalToShow: null,
          modalData: null,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'insert-job-title'),
          info: {
            ...state.info,
            days: state.info.days.concat([action.day]),
          },
        };
      }
      case constants.COLLABORATOR_INSERT_JOB_TITLE: {
        return {
          ...state,
          info: {
            ...state.info,
            days: state.info.days.concat([action.info.day]),
          },
        };
      }
      case constants.DELETE_JOB_TITLE_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'delete-job-title'),
        };
      case constants.DELETE_JOB_TITLE_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'delete-job-title'),
          info: {
            ...state.info,
            days: state.info.days.filter((day) => day.id !== action.id),
          },
          modalToShow: null,
          modalData: null,
        };
      case constants.COLLABORATOR_DELETE_JOB_TITLE:
        return {
          ...state,
          info: {
            ...state.info,
            days: state.info.days.filter((day) => day.id !== action.info.id),
          },
        };
      case constants.INSERT_PRODUCTS_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'insert-products'),
        };
      case constants.INSERT_PRODUCTS_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'insert-products'),
          info: {
            ...state.info,
            tasks: state.info.tasks.concat(action.tasks),
            days: state.info.days.concat(action.days),
          },
          modalToShow: null,
          modalData: null,
        };
      case constants.UPDATE_PRICELIST_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'change-pricelist'),
        };
      case constants.UPDATE_PRICELIST_SUCCESS:
        return {
          ...state,
          waitingFor: BudgetReducer.removeWaitingFor(state, 'change-pricelist'),
          info: {
            ...state.info,
            ...BudgetInfoService.formatBudgetInfo(
              action.budgetInfo,
            ),
          },
        };
      case constants.UPDATE_PRICE_LIST_AREA_REQUEST:
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'change-pricelist'),
        };
      case constants.UPDATE_PRICE_LIST_AREA_SUCCESS:
        return {
          ...state,
          info: {
            ...state.info,
            ...BudgetInfoService.formatBudgetInfo(
              action.budgetInfo,
            ),
          },
          waitingFor: BudgetReducer.removeWaitingFor(state, 'change-pricelist'),
        };
      case constants.COLLABORATOR_CHANGE_PRICELIST: {
        return {
          ...state,
          info: {
            ...state.info,
            ...BudgetInfoService.formatBudgetInfo(
              action.info,
            ),
          },
        };
      }
      case constants.SET_SCROLLING_COMPONENT_AMOUNT: {
        return {
          ...state,
          scrollingComponentAmount: action.amount,
        };
      }
      case constants.UPDATE_BUDGET_LEVEL_VISIBLE_SUCCESS: {
        return {
          ...state,
          info: {
            ...state.info,
            levels: state.info.levels.map((level) => {
              if (level.level.id === action.budgetLevel.level.id) {
                return {
                  ...level,
                  is_visible: action.budgetLevel.is_visible,
                };
              }
              return level;
            }),
            budget_price_list_levels: state.info.budget_price_list_levels
              .map((budgetPriceListLevel) => {
                if (budgetPriceListLevel.level.id === action.budgetLevel.level.id) {
                  return {
                    ...budgetPriceListLevel,
                    is_visible: action.budgetLevel.is_visible,
                  };
                }
                return budgetPriceListLevel;
              }),
          },
        };
      }
      case constants.AREA_COLLAPSE: {
        return {
          ...state,
          collapsedAreas: state.collapsedAreas
            .filter((id) => id !== action.areaId)
            .concat(action.areaId),
        };
      }
      case constants.AREA_EXPAND: {
        return {
          ...state,
          collapsedAreas: state.collapsedAreas.filter((id) => id !== action.areaId),
        };
      }
      case constants.BASELINE_UPDATE_REQUEST: {
        return {
          ...state,
          waitingFor: BudgetReducer.addWaitingFor(state, 'save-budget-info'),
        };
      }
      case constants.BASELINE_UPDATE_SUCCESS: {
        return {
          ...state,
          baselines: state.baselines.map((baseline) => ({
            ...baseline,
            baseline: baseline.version === action.baseline,
          })),
          versions: state.versions.map((version) => ({
            ...version,
            baseline: version.version === action.baseline,
          })),
          waitingFor: BudgetReducer.removeWaitingFor(state, 'save-budget-info'),
        };
      }
      default:
        return state;
    }
  }
}

module.exports = BudgetReducer;
