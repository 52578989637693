module.exports = class ChartLegendItem {
  /**
   * @param label {string}
   * @param color {string}
   */
  constructor(label, color = '') {
    this.label = label;
    this.color = color;
  }
};
