const ChartConfigDimension = require('./ChartConfigDimension');

class ChartConfigClientDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigClientDimensionGrouping} grouping
   */
  constructor(grouping) {
    super(ChartConfigClientDimension.KEY);
    this.grouping = grouping;
  }
}

ChartConfigClientDimension.KEY = 'client';

module.exports = ChartConfigClientDimension;
