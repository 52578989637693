'use strict';

/* eslint-disable default-case,eqeqeq */

Wethod.module('SettingsApp.Team', function (Team, Wethod, Backbone, Marionette, $, _) {
  this.GetInvitationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/account/invitations',
    idAttribute: 'code',
  });
  this.GetInvitationCollection = Wethod.Utility.BaseCollection.extend({
    model: Team.GetInvitationModel,
    url: APIURL + '/account/invitations',
  });

  this.SaveInvitationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/account/inviteusers',
    idAttribute: 'code',
  });
  this.SaveInvitationCollection = Wethod.Utility.BaseCollection.extend({
    model: Team.SaveInvitationModel,
    url: APIURL + '/account/inviteusers',
  });

  this.GetLevelsModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/levels/',
  });
  this.GetLevelsCollection = Wethod.Utility.BaseCollection.extend({
    model: Team.GetLevelsModel,
    url: APIURL + '/levels/',
  });

  this.SaveLevelModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/account/setemployeelevel',
  });

  this.SaveRoleModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/account/setemployeerole',
  });

  this.RolesModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/role/',
  });
  this.RolesCollection = Wethod.Utility.BaseCollection.extend({
    model: Team.RolesModel,
    url: APIURL + '/role/',
  });

  this.ActualModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/account/employeesinfo',
  });
  this.ActualCollection = Wethod.Utility.BaseCollection.extend({
    model: Team.ActualModel,
    url: APIURL + '/account/employeesinfo',
  });

  // tag category
  this.TagCategoryModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/tagempcategory',
    defaults: {
      id: null,
      name: '',
      sort: 999,
    },
  });
  this.TagCategoryCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/tagempcategory?type=SELECT&limit=2',
    model: Team.TagCategoryModel,
    comparator: function (model) {
      return model.get('id') || model.get('sort');
    },
  });

  // employee Tag model
  this.EmployeeTagModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/tagemp',
    validate: function (attrs) {
      var errors = [];
      _.each(attrs, function (val, key) {
        var error = {};
        switch (key) {
          case 'name':
            if (attrs[key].trim() === '') {
              error[key] = 'The Tag must be NOT empty';
              errors.push(error);
            }
            break;
        }
      });

      return (errors.length > 0) ? errors : false;
    },
  });
  this.EmployeeTagRelationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/employeetag',
  });

  // model & collection relativi all'autocompletamento
  this.AutocompleteModel = Wethod.Utility.BaseModel.extend({});
  this.AutocompleteCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = (!_.isUndefined(this.options.whatSearch)) ? '?hints=' + this.options.whatSearch + '&category=' + this.options.category : '?category=' + this.options.category;
      return APIURL + '/tagempboard/autocomplete' + params;
    },
    model: Team.AutocompleteModel,
  });

  // model & collection related to job titles autocomplete
  this.JobTitleAutocompleteModel = Wethod.Utility.BaseModel.extend({});
  this.JobTitleAutocompleteCollection = Wethod.Utility.AutocompleteCollection.extend({
    url: function () {
      var params = '?search=' + this.options.whatSearch + '&levelId=' + this.options.levelId + '&archived=' + this.options.archived;
      return APIURL + '/job-title' + params;
    },
    model: Team.JobTitleAutocompleteModel,
  });

  this.JobTitlesModel = Wethod.Utility.BaseModel.extend({});
  this.JobTitlesCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/job-title',
    model: Team.JobTitlesModel,
  });

  this.JobTitleUpdateModel = Wethod.Utility.BaseModel.extend({});
  this.JobTitleUpdateCollection = Wethod.Utility.BaseCollection.extend({
    url: APIURL + '/employee-job-title',
    model: Team.JobTitleUpdateModel,
  });

  // madal model
  this.ModalModel = Wethod.Utility.BaseModel.extend();

  // recupero dati per cambio piano
  this.ChangePlanModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var modifier = (!_.isUndefined(this.options.modifier)) ? this.options.modifier : '';
      return APIURL + '/payment/notifyplanchange?modifier=' + modifier;
    },
  });

  this.EmployeeInvitationModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/account/inviteusers',
    validate: function (attrs) {
      var errors = [];
      _.each(attrs, function (val, key) {
        var error = {};
        switch (key) {
          case 'employee_email':
          case 'employee_role':
          case 'employee_level':
          case 'url':
            if (attrs[key].trim() === '') {
              errors.push(error);
            }
            break;
        }
      });
      return (errors.length > 0) ? errors : false;
    },
  });

  this.EmployeeExternalId = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/account/external-identifier/';
    },
  });
  this.EmployeeExternalIdCollection = Wethod.Utility.BaseCollection.extend({
    model: Team.EmployeeExternalId,
    url: APIURL + '/account/external-identifier/',
  });

  var API = {
    getInvitations: function () {
      var invitations = new Team.GetInvitationCollection();
      var defer = $.Deferred();

      invitations.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getLevels: function () {
      var levels = new Team.GetLevelsCollection();
      var defer = $.Deferred();

      levels.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    getRoles: function () {
      var roles = new Team.RolesCollection();
      var defer = $.Deferred();

      roles.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    createInvitation: function (invitationObj) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/inviteusers',
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
        data: JSON.stringify([invitationObj]),
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          var invitationCollection = new Team.SaveInvitationCollection(response.data);
          defer.resolve(invitationCollection);
        }
      }).fail(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    getActual: function () {
      var roles = new Team.ActualCollection();
      var defer = $.Deferred();

      roles.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });
      return defer.promise();
    },
    saveLevel: function (employeeId, levelId) {
      if (!_.isUndefined(employeeId) && !_.isUndefined(levelId)) {
        var defer = $.Deferred();
        $.ajax({
          method: 'POST',
          crossDomain: true,
          url: APIURL + '/account/setemployeelevel',
          xhrFields: {
            withCredentials: true,
          },
          data: {
            employeeId: employeeId,
            levelId: levelId,
          },
        }).done(function (response) {
          if (response.code != '200') {
            defer.resolve(undefined);
          } else {
            var SaveLevelsModel = new Team.SaveLevelModel(response.data);
            defer.resolve(SaveLevelsModel);
          }
        }).fail(function (response) {
          defer.resolve(response);
        });

        return defer.promise();
      }
      return null;
    },
    saveRole: function (employeeId, roleId) {
      if (!_.isUndefined(employeeId) && !_.isUndefined(roleId)) {
        var postData = {
          employeeId: employeeId,
          roleId: roleId,
        };

        var defer = $.Deferred();
        $.ajax({
          method: 'POST',
          crossDomain: true,
          url: APIURL + '/account/setemployeerole',
          xhrFields: {
            withCredentials: true,
          },
          data: postData,
        }).done(function (response) {
          if (response.code != '200') {
            defer.resolve(undefined);
          } else {
            var SaveLevelsModel = new Team.SaveRoleModel(response.data);
            defer.resolve(SaveLevelsModel);
          }
        }).fail(function (response) {
          defer.resolve(response);
        });

        return defer.promise();
      }
      return null;
    },
    getAutoComplete: function (data) {
      var record = new Team.AutocompleteCollection(data);
      var defer = $.Deferred();

      record.fetch({
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    getJobTitleAutoComplete: function (data) {
      var record = new Team.JobTitleAutocompleteCollection(data);
      var defer = $.Deferred();

      record.fetch({
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    getJobTitles: function (data) {
      var record = new Team.JobTitlesCollection(data);
      var defer = $.Deferred();

      record.fetch({
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    getChangeLog: function (employeeId) {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/account/employee-changelog/' + employeeId,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code !== 200 && response.code !== 201) {
          defer.reject(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    updateJobTitle: function (employeeId, jobTitleId) {
      if (!_.isUndefined(employeeId) && !_.isUndefined(jobTitleId)) {
        var postData = {
          employee_id: employeeId,
          job_title_id: jobTitleId,
        };

        var defer = $.Deferred();
        $.ajax({
          method: 'POST',
          crossDomain: true,
          url: APIURL + '/employee-job-title',
          xhrFields: {
            withCredentials: true,
          },
          data: JSON.stringify(postData),
        }).done(function (response) {
          if (response.code != '200') {
            defer.resolve(undefined);
          } else {
            var JobTitleUpdateCollection = new Team.JobTitleUpdateCollection(response.data);
            defer.resolve(JobTitleUpdateCollection);
          }
        }).fail(function (response) {
          defer.resolve(response);
        });

        return defer.promise();
      }
      return null;
    },
    deleteJobTitle: function (employeeId) {
      if (!_.isUndefined(employeeId)) {
        var postData = {
          employee_id: employeeId,
        };

        var defer = $.Deferred();
        $.ajax({
          method: 'DELETE',
          crossDomain: true,
          url: APIURL + '/employee-job-title',
          xhrFields: {
            withCredentials: true,
          },
          data: JSON.stringify(postData),
        }).done(function (response) {
          if (response.code != '200') {
            defer.resolve(undefined);
          } else {
            var JobTitleUpdateCollection = new Team.JobTitleUpdateCollection(response.data);
            defer.resolve(JobTitleUpdateCollection);
          }
        }).fail(function (response) {
          defer.resolve(response);
        });

        return defer.promise();
      }
      return null;
    },
    insertTag: function (data) {
      var employeeTag = new Team.EmployeeTagModel(data);
      var defer = $.Deferred();

      employeeTag.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    insertTagRelation: function (data) {
      var employeeTagRel = new Team.EmployeeTagRelationModel(data);
      var defer = $.Deferred();

      employeeTagRel.save(null, {
        success: function (model) {
          defer.resolve(model);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
        method: 'put',
      });
      return defer.promise();
    },
    deleteTagRelation: function (data) {
      var employeeTagRel = new Team.EmployeeTagRelationModel(data);
      var defer = $.Deferred();

      employeeTagRel.destroy({
        success: function (model, response) {
          defer.resolve(response);
        },
        error: function (model, response) {
          defer.resolve(response);
        },
        wait: true,
      });

      return defer.promise();
    },
    archivedEmployee: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/employeearchive/' + options.id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    unarchivedEmployee: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/employeeunarchive/' + options.id,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    teamDeleteInvitation: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/account/retireinvitation?code=' + options.code,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    getTagCategory: function () {
      var collection = new Team.TagCategoryCollection();
      var defer = $.Deferred();

      collection.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    saveTagCategory: function (data) {
      var model = new Team.TagCategoryModel(data);
      var defer = $.Deferred();

      model.save(null, {
        success: function (responseModel) {
          defer.resolve(responseModel);
        },
        error: function (responseModel, response) {
          defer.resolve(response);
        },
        wait: true,
      });
      return defer.promise();
    },
    setTimesheetNeeded: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/employee-timesheet-needed/' + options.employeeId + '?needed=' + options.timesheetNeeded,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    updateAllocationManager: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/employeeallocationmanager/' + options.employee_id,
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          allocation_manager_id: options.allocation_manager_id,
        }),
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    updateEmployeePricelistLevel: function (options) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/set-employee-price-list-level?employeeId=' + options.employeeId + '&levelId=' + options.levelId + '&pricelistId=' + options.pricelistId,
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
        contentType: 'application/json',
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    showOnPlanning: function (employeeId) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/employeevisibility/' + employeeId,
        data: {
          visible: true,
        },
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    hideFromPlanning: function (employeeId) {
      var defer = $.Deferred();
      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/employeevisibility/' + employeeId,
        data: {
          visible: false,
        },
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        if (response.code != 200 && response.code != 500) {
          defer.resolve(undefined);
        } else if (response.code == 500) {
          defer.resolve(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    importMetadata: function (formData) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        url: APIURL + '/import/team/knowledge',
        crossDomain: true,
        xhrFields: {
          withCredentials: true,
        },
        processData: false,
        contentType: false,
        data: formData,
      }).done(function (response) {
        if (response.code === 200) {
          defer.resolve();
        } else if (response.code === 400) {
          defer.reject(response.data);
        }
      }).fail(function () {
        defer.resolve(undefined);
      });

      return defer.promise();
    },
    exportMetadata: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        url: APIURL + '/export/team/knowledge',
        crossDomain: true,
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      });

      return defer.promise();
    },
    saveUserCode: function (data, employee) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/account/employee/' + employee + '/external-identifier',
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(data),
      }).done(function (response) {
        if (response.code !== 200 && response.code !== 201) {
          defer.reject(response);
        } else {
          defer.resolve(response.data);
        }
      }).fail(function (response) {
        defer.reject(response);
      });

      return defer.promise();
    },
    updateUserCode: function (model) {
      var defer = $.Deferred();

      model.clone().save(null, {
        wait: true,
        success: function (data, response) {
          if (response.code !== 200 && response.code !== 201) {
            defer.reject(response);
          } else {
            defer.resolve(model);
          }
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
    deleteUserCode: function (model) {
      var defer = $.Deferred();

      model.destroy({
        wait: true,
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:invitations', function () {
    return API.getInvitations();
  });

  Wethod.reqres.setHandler('get:levels', function () {
    return API.getLevels();
  });

  Wethod.reqres.setHandler('get:roles', function () {
    return API.getRoles();
  });

  Wethod.reqres.setHandler('create:invitation', function (invitationData) {
    return API.createInvitation(invitationData);
  });

  Wethod.reqres.setHandler('get:actual', function () {
    return API.getActual();
  });

  Wethod.reqres.setHandler('save:level', function (employeeId, levelId) {
    return API.saveLevel(employeeId, levelId);
  });

  Wethod.reqres.setHandler('save:role', function (employeeId, roleId) {
    return API.saveRole(employeeId, roleId);
  });

  Wethod.reqres.setHandler('get:tag:autocomplete', function (data) {
    return API.getAutoComplete(data);
  });

  Wethod.reqres.setHandler('get:jobTitles', function () {
    return API.getJobTitles();
  });

  Wethod.reqres.setHandler('get:jobTitle:autocomplete', function (data) {
    return API.getJobTitleAutoComplete(data);
  });

  Wethod.reqres.setHandler('update:employee:jobTitle', function (employeeId, jobTitleId) {
    return API.updateJobTitle(employeeId, jobTitleId);
  });

  Wethod.reqres.setHandler('delete:employee:jobTitle', function (employeeId) {
    return API.deleteJobTitle(employeeId);
  });

  Wethod.reqres.setHandler('team:insert:tag', function (data) {
    return API.insertTag(data);
  });
  Wethod.reqres.setHandler('team:insert:tag:relation', function (data) {
    return API.insertTagRelation(data);
  });
  Wethod.reqres.setHandler('team:delete:tag:relation', function (data) {
    return API.deleteTagRelation(data);
  });

  // archive
  Wethod.reqres.setHandler('team:archive:employee', function (options) {
    return API.archivedEmployee(options);
  });
  Wethod.reqres.setHandler('team:unarchive:employee', function (options) {
    return API.unarchivedEmployee(options);
  });

  // show hide from planning
  Wethod.reqres.setHandler('team:show:employee', function (options) {
    return API.showOnPlanning(options);
  });
  Wethod.reqres.setHandler('team:hide:employee', function (options) {
    return API.hideFromPlanning(options);
  });

  // Set if timesheet needed
  Wethod.reqres.setHandler('team:timesheet:needed', function (options) {
    return API.setTimesheetNeeded(options);
  });

  Wethod.reqres.setHandler('team:allocation:manager:update', function (options) {
    return API.updateAllocationManager(options);
  });

  Wethod.reqres.setHandler('team:employee:pricelist:level:update', function (options) {
    return API.updateEmployeePricelistLevel(options);
  });

  // tag category
  Wethod.reqres.setHandler('get:tag:category', function () {
    return API.getTagCategory();
  });
  Wethod.reqres.setHandler('save:tagCategory', function (data) {
    return API.saveTagCategory(data);
  });
  // delete invitation
  Wethod.reqres.setHandler('team:delete:invitation', function (options) {
    return API.teamDeleteInvitation(options);
  });

  Wethod.reqres.setHandler('team:metadata:import', function (formData) {
    return API.importMetadata(formData);
  });

  Wethod.reqres.setHandler('team:metadata:export', function () {
    return API.exportMetadata();
  });

  // Set user code
  Wethod.reqres.setHandler('team:save:user-code', function (options, employee) {
    return API.saveUserCode(options, employee);
  });
  Wethod.reqres.setHandler('team:update:user-code', function (model) {
    return API.updateUserCode(model);
  });
  Wethod.reqres.setHandler('team:delete:user-code', function (model) {
    return API.deleteUserCode(model);
  });

  // changelog
  Wethod.reqres.setHandler('get:employee:change-log', function (employeeId) {
    return API.getChangeLog(employeeId);
  });
});
