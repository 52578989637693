const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Chart = {
  list(filters = {}) {
    let url = '/charts';
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }

    return request({
      method: 'get',
      url,
    });
  },
  getPoints(chartId, filters = {}) {
    let url = `/charts/${chartId}/data`;
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }
    return request({
      method: 'get',
      url,
    });
  },
  getTableData(chartId, filters = {}) {
    let url = `/charts/${chartId}/table-data`;
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }
    return request({
      method: 'get',
      url,
    });
  },
  getTableTotals(chartId, filters = {}) {
    let url = `/charts/${chartId}/table-totals`;
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }
    return request({
      method: 'get',
      url,
    });
  },
};

module.exports = Chart;
