// Return the proper link for the alert, given the alert and the project.
const AlertLink = {
  getAlertLink(alert, project = null, opportunity = null, intercompanyToken = null) {
    switch (alert.action) {
      case 'pipeline':
        return `#pipeline/projects?id=${project?.id}`;
      case 'pipeline-search':
        return `#pipeline/projects?job-order=${project?.job_order}`;
      case 'planning':
        return project ? `#planning/people?project_id=${project.id}` : '#planning/people';
      case 'project-status':
        return project ? `#project-status/${project.id}` : '#project-status';
      case 'timesheet':
        return '#timesheet';
      case 'budget':
        return `#pipeline/budget/${project?.id}`;
      case 'report-details':
        return `#project/${project?.id}/report`;
      case 'basket':
        return `#pipeline/basket/${opportunity?.id}`;
      case 'orders':
        return '#finance/order';
      case 'invoices':
        return '#finance/invoice';
      case 'production-plan':
        return `#planning/production-plan?project_id=${project?.id}`;
      case 'budget-intercompany':
        return `#pipeline/intercompany/${intercompanyToken}`;
      case 'canvas':
        return `#project/${project?.id}/canvas`;
      case 'team':
        return '#settings/team';
      default:
        return '';
    }
  },
};

module.exports = AlertLink;
