const React = require('react');
const Loader = require('../../Loader/Loader.react');

const ChartLoader = () => (
  <div className="wethod-chart__loader">
    <Loader />
  </div>
);

module.exports = ChartLoader;
