module.exports = class ChartConfigProbabilityDimensionGrouping {
  /**
   * @param {ChartConfigProbabilityDimensionGroupingValue[]} items
   */
  constructor(items) {
    this.type = 'cluster';
    this.items = items;
  }

  /**
   * Return item with given name.
   * @param {string} name
   * @return {ChartConfigProbabilityDimensionGroupingValue}
   */
  findItem(name) {
    return this.items.find((item) => item.name === name);
  }
};
