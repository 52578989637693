/* eslint-disable react/prop-types */
const React = require('react');
const ShowIf = require('../../../../ShowIf/ShowIf.react');
const NewLabel = require('../NewSectionLabel.react');

module.exports = class Section extends React.Component {
  getTarget() {
    return this.props.blocked ? '_blank' : '_self';
  }

  isNew() {
    return this.props.isNew;
  }

  render() {
    return (
      <li className="app-nav__section">
        <a href={this.props.section.getUrl()}
          onClick={this.props.onClick}
          target={this.getTarget()}>
          {this.props.section.getLabel()}
        </a>
        <ShowIf condition={this.isNew()}>
          <NewLabel />
        </ShowIf>
      </li>
    );
  }
};
