/* eslint-disable no-param-reassign */
const ColorService = {
  /**
   * Return a list containing the given amount of HSL colors.
   * These colors are generated to be as different from each other as possible.
   *
   * @param {number} amount
   * @return {string[]}
   */
  getColors: (amount) => {
    const colors = [];
    const hueStep = 360 / amount; // Divide the color wheel into equal parts

    for (let i = 0; i < amount; i++) {
      const hue = Math.round(hueStep * i) + 208; // hue, 208 is blue wethod
      const saturation = 94; // same saturation as blue wethod
      const lightness = 63; // same lightness as blue wethod

      const color = ColorService.hslToRgb(hue, saturation, lightness);
      colors.push(color);
    }

    return colors;
  },

  hslToRgb: (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;

    let r;
    let g;
    let
      b;

    if (s === 0) {
      // eslint-disable-next-line no-multi-assign
      r = g = b = l; // Achromatic
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }

    return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)})`;
  },
  rgbToRgba(rgb, opacity = 1) {
    return rgb.replace(')', `,${opacity})`);
  },
};

module.exports = ColorService;
