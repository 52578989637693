module.exports = class BarChartConfigDimensions {
  /**
   * @param {ChartConfigDimension} x
   * @param {ChartConfigDimension} y
   * @param {ChartConfigDimension} color
   */
  constructor(x, y, color = null) {
    this.x = x;
    this.y = y;
    this.color = color;
  }

  /**
   * @return {ChartConfigDimension[]}
   */
  getDimensionsList() {
    return [this.x, this.y, this.color];
  }
};
