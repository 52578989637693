const React = require('react');
const { Provider } = require('react-redux');
const RevenuesInsights = require('./containers/RevenuesInsights');
require('./style.scss');

module.exports = ({ store }) => (
  <Provider store={store}>
    <RevenuesInsights />
  </Provider>
);
