module.exports = class BarChartSeries {
  /**
   * @param name {string}
   * @param color {string}
   * @param data {Array.<[number, number]>} List of objects, each child array has the
   * form [categoryIndex, yValue]
   */
  constructor(name, color, data) {
    this.name = name;
    this.color = color;
    this.data = data;
  }
};
