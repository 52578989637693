const React = require('react');
const PropTypes = require('prop-types');
const Button = require('./Button.react');

/**
 * Button with rounded style.
 * Ideal (but not limited) to display plain text.
 *
 * @param children
 * @param disabled
 * @param className
 * @param onClick
 * @param inputRef
 * @returns {JSX.Element}
 * @constructor
 */
const RoundedButton = ({ children, disabled, className, onClick, inputRef, border, label }) => {
  const getClassName = () => {
    let name = 'wethod-button';
    name = className ? `${name} ${className}` : name;
    name = border ? name : `${name} wethod-button--no-border`;

    return name;
  };

  return (
    <Button inputRef={inputRef}
      label={label}
      className={getClassName()}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </Button>
  );
};

RoundedButton.defaultProps = {
  disabled: false,
  className: '',
  inputRef: null,
  border: true,
  label: undefined,
};

RoundedButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  /**
   * Expose button ref to parent.
   * @param ref {HTMLElement}
   * */
  inputRef: PropTypes.func,
  /**
   * Whether to show the button with or without the border
   */
  border: PropTypes.bool,
};

module.exports = RoundedButton;
