class Chart {
  /**
   * @param {number} id
   * @param {?number} parentId
   * @param {string} dataSource
   * @param {ChartConfig} config
   */
  constructor(id, parentId, dataSource, config) {
    this.id = id;
    this.parentId = parentId;
    this.dataSource = dataSource;
    this.config = config;
  }

  /**
   * @return {ChartConfigTable}
   */
  getTableConfig() {
    return this.config.table;
  }
}

Chart.DATA_SOURCE_REVENUES = 'revenues';

Chart.AVAILABLE_DATA_SOURCES = [Chart.DATA_SOURCE_REVENUES];

module.exports = Chart;
