const React = require('react');
const PropTypes = require('prop-types');
const ChartModel = require('../../../../../../models/Chart');
const ChartPicker = require('./InsightLevelChartPicker.react');
const ChartContainer = require('./InsightLevelChartContainer.react');
const Chart = require('../models/Chart');
const ChartConfigFilter = require('../models/ChartConfigFilter/ChartConfigFilter');
const BarChartConfigDimensions = require('../models/BarChartConfigDimensions');
const ChartConfigDimension = require('../models/ChartConfigDimension/ChartConfigDimension');
const ChartConfigProbabilityDimension = require('../models/ChartConfigDimension/ChartConfigProbabilityDimension');
const ChartConfigProbabilityDimensionGrouping = require('../models/ChartConfigDimension/ChartConfigProbabilityDimensionGrouping');
const ChartConfigProbabilityDimensionGroupingValue = require('../models/ChartConfigDimension/ChartConfigProbabilityDimensionGroupingValue');
const ChartConfigTimeDimension = require('../models/ChartConfigDimension/ChartConfigTimeDimension');
const ChartConfigTimeDimensionGrouping = require('../models/ChartConfigDimension/ChartConfigTimeDimensionGrouping');
const ChartConfigClientDimension = require('../models/ChartConfigDimension/ChartConfigClientDimension');
const ChartConfigClientDimensionGrouping = require('../models/ChartConfigDimension/ChartConfigClientDimensionGrouping');
const ChartConfig = require('../models/ChartConfig');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');
const TableViewContainer = require('./InsightLevelTableViewContainer.react');
const ChartConfigTable = require('../models/ChartConfigTable');
const ChartConfigTableColumn = require('../models/ChartConfigTableColumn');

class InsightLevel extends React.Component {
  /**
   * Return error code based on given reason.
   * @param {string|number} reason
   * @return {string}
   */
  static getError(reason) {
    switch (reason) {
      case 403:
        return InsightLevel.ERROR_UNAUTHORIZED;
      default:
        return InsightLevel.ERROR_UNKNOWN;
    }
  }

  static parseChartFilters(filters = []) {
    return filters.map((filter) => new ChartConfigFilter(filter.target, filter.type));
  }

  /**
   *
   * @param {ChartConfigDimension} dimension
   */
  static buildChartConfigDimension(dimension) {
    const { key } = dimension;
    switch (key) {
      case 'probability': {
        const groupingItems = dimension.grouping.values
          .map((item) => new ChartConfigProbabilityDimensionGroupingValue(item.name, item.values));
        const grouping = new ChartConfigProbabilityDimensionGrouping(groupingItems);
        return new ChartConfigProbabilityDimension(grouping);
      }
      case 'time': {
        const grouping = new ChartConfigTimeDimensionGrouping(dimension.grouping.value);
        return new ChartConfigTimeDimension(grouping);
      }
      case 'client': {
        const grouping = new ChartConfigClientDimensionGrouping(dimension.grouping.type,
          dimension.grouping.value);
        return new ChartConfigClientDimension(grouping);
      }
      default:
        return new ChartConfigDimension(dimension.key);
    }
  }

  /**
   * @param column
   * @return {ChartConfigTableColumn}
   */
  static parseTableColumn(column) {
    const columnNames = {
      client: 'Client',
      name: 'Project',
      job_order: 'Job Order',
      probability: 'Probability',
      time: 'Time',
      revenues: 'Revenues',
    };

    return new ChartConfigTableColumn(column.key, columnNames[column.key], column.sort_key);
  }

  /**
   * @param columns
   * @return {ChartConfigTableColumn[]}
   */
  static parseTableColumns(columns) {
    return columns
      .map(InsightLevel.parseTableColumn);
  }

  /**
   * @param table
   * @return {ChartConfigTable}
   */
  static parseTableConfig(table) {
    const columnList = InsightLevel.parseTableColumns(table.columns);
    return new ChartConfigTable(columnList);
  }

  static parseChartDimensions(dimensions, type) {
    switch (type) {
      case ChartConfig.TYPE_STACKED_BAR: {
        const x = InsightLevel.buildChartConfigDimension(dimensions.x);
        const y = InsightLevel.buildChartConfigDimension(dimensions.y);
        const color = InsightLevel.buildChartConfigDimension(dimensions.color);
        return new BarChartConfigDimensions(x, y, color);
      }
      case ChartConfig.TYPE_BAR: {
        const x = InsightLevel.buildChartConfigDimension(dimensions.x);
        const y = InsightLevel.buildChartConfigDimension(dimensions.y);
        return new BarChartConfigDimensions(x, y);
      }
      default:
        throw new Error(`'${type}' is not a valid chart type.`);
    }
  }

  static parseChartConfig(config) {
    const filters = InsightLevel.parseChartFilters(config.filters);
    const dimensions = InsightLevel.parseChartDimensions(config.dimensions, config.type);
    const tableConfig = InsightLevel.parseTableConfig(config.table);

    return new ChartConfig(config.name, config.description, config.type, filters, dimensions,
      tableConfig);
  }

  static parseChart(chart) {
    return new Chart(chart.id, chart.parent_id, chart.data_source,
      InsightLevel.parseChartConfig(chart.config));
  }

  constructor(props) {
    super(props);

    /**
     * @type {{isLoading: boolean, charts: Chart[], selectedId: ?number}}
     */
    this.state = {
      charts: [],
      isLoading: true,
      selectedId: null, // ID of the currently selected chart
      childrenFilters: [], // List of filters to apply to children levels
    };

    this.updateSelected = this.updateSelected.bind(this);
    this.updateChildrenFilters = this.updateChildrenFilters.bind(this);
  }

  componentDidMount() {
    this.loadCharts();
  }

  componentDidUpdate(prevProps) {
    const parentChanged = this.props.parent !== prevProps.parent;
    if (parentChanged) {
      this.loadCharts();
    }
  }

  /**
   *
   * @return {Chart}
   */
  getVisibleChart() {
    return this.state.charts.find((chart) => chart.id === this.state.selectedId);
  }

  getChildLevel() {
    const visibleChart = this.getVisibleChart();
    if (visibleChart !== undefined) {
      const filters = [...this.props.filters, ...this.state.childrenFilters];
      return (
        <InsightLevel parent={visibleChart.id}
          updateError={this.props.updateError}
          filters={filters}
          dataSource={this.props.dataSource}
          tableConfig={visibleChart.getTableConfig()} />
      );
    }
    return null;
  }

  getChartContainer() {
    const visibleChart = this.getVisibleChart();
    if (this.state.selectedId === 'table-view') {
      return (
        <TableViewContainer filters={this.props.filters}
          parentId={this.props.parent}
          config={this.props.tableConfig} />
      );
    }
    return (
      <ChartContainer chart={visibleChart}
        isLoading={this.state.isLoading}
        filters={this.props.filters}
        updateChildrenFilters={this.updateChildrenFilters} />
    );
  }

  updateSelected(id) {
    this.setState({ selectedId: id });
  }

  updateChildrenFilters(filters) {
    this.setState({ childrenFilters: filters });
  }

  /**
   * Return true if this level must display a table view.
   * @return {boolean}
   */
  hasTableView() {
    return this.props.tableConfig !== null;
  }

  loadCharts() {
    const options = {
      data_source: this.props.dataSource,
      has_parent: this.props.parent !== null,
    };
    if (this.props.parent) {
      options.parent = this.props.parent;
    }
    ChartModel
      .list(options)
      .done((charts) => {
        const parsedCharts = charts.map(InsightLevel.parseChart);
        const firstChartId = charts.length ? charts[0].id : null;

        this.setState({
          charts: parsedCharts,
          isLoading: false,
          selectedId: this.hasTableView() ? 'table-view' : firstChartId,
        });
      })
      .fail((message, code) => this.props.updateError(InsightLevel.getError(code)));
  }

  render() {
    return (
      <ShowIf condition={this.state.selectedId !== null}>
        <div className="wethod-insight-level">
          <ChartPicker charts={this.state.charts}
            selectedId={this.state.selectedId}
            onClick={this.updateSelected}
            withTableView={this.hasTableView()} />
          <ShowIf condition={!this.state.isLoading}>
            {this.getChartContainer()}
          </ShowIf>
          {this.getChildLevel()}
        </div>
      </ShowIf>
    );
  }
}

InsightLevel.ERROR_UNAUTHORIZED = 'error-unauthorized';
InsightLevel.ERROR_UNKNOWN = 'error-unknown';

InsightLevel.defaultProps = {
  filters: [],
  parent: null,
  tableConfig: null,
};

InsightLevel.propTypes = {
  updateError: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(ChartConfigFilter),
  parent: PropTypes.number,
  dataSource: PropTypes.oneOf(Chart.AVAILABLE_DATA_SOURCES).isRequired,
  tableConfig: PropTypes.instanceOf(ChartConfigTable),
};

module.exports = InsightLevel;
