const React = require('react');
const PropTypes = require('prop-types');
const T = require('../../Typography/Typography.react');
const Loader = require('./ChartLoader.react');

require('./style.scss');

/**
 * @param {JSX.Element} children Element to render as chart body
 * @param {string} title
 * @param {string} description
 * @param isLoading
 * @return {JSX.Element}
 * @constructor
 */
const Chart = ({
  children, title = '', description = '',
  isLoading = false,
}) => {
  const body = isLoading ? <Loader /> : children;

  return (
    <div className="wethod-chart">
      <div className="wethod-chart__header">
        <div className="wethod-chart__name">
          <T weight={T.WEIGHTS.SEMIBOLD} size={T.SIZES.PX16}>
            {title}
          </T>
        </div>
        <div className="wethod-chart__description">
          <T size={T.SIZES.PX14}>
            {description}
          </T>
        </div>
      </div>
      <div className="wethod-chart__body">
        {body}
      </div>
    </div>
  );
};

Chart.defaultProps = {
  title: '',
  description: '',
  isLoading: false,
};

Chart.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
};

module.exports = Chart;
