module.exports = class ChartConfigTable {
  /**
   * Config of table view related to chart.
   * @param {ChartConfigTableColumn[]} columns
   */
  constructor(columns) {
    this.columns = columns;
  }

  /**
   * @return {ChartConfigTableColumn[]}
   */
  getColumns() {
    return this.columns;
  }
};
