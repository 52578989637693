const React = require('react');
const SectionHeader = require('../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const InsightLevel = require('./InsightLevel.react');
const ErrorBody = require('../../../../../../common/react/SectionError/SectionError.react');

module.exports = class RevenuesInsights extends React.Component {
  constructor(props) {
    super(props);

    /**
     * @type {{error: ?string}}
     */
    this.state = {
      error: null,
    };

    this.updateError = this.updateError.bind(this);
  }

  updateError(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error === InsightLevel.ERROR_UNAUTHORIZED) {
      return <ErrorBody message="You are not allowed to be here!" />;
    }
    if (this.state.error === InsightLevel.ERROR_UNKNOWN) {
      return <ErrorBody message="Ops! Something went wrong :(" />;
    }
    return (
      <div className="wethod-section-body wethod-revenues-insights">
        <SectionHeader
          current_section="Revenues"
          helper_url="reports/index/#revenues"
          big />
        <InsightLevel parent={null} dataSource="revenues" updateError={this.updateError} />
      </div>
    );
  }
};
