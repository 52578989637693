module.exports = class ChartConfigProbabilityDimensionGroupingValue {
  /**
   * @param {string} name
   * @param {number[]} values
   */
  constructor(name, values) {
    this.name = name;
    this.values = values;
  }
};
