const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterProbability extends ChartConfigFilter {
  constructor(type) {
    super(ChartConfigFilterProbability.TARGET, type);
  }
}

ChartConfigFilterProbability.TARGET = 'probability';

module.exports = ChartConfigFilterProbability;
