const TimeDimension = require('./ChartConfigDimension/ChartConfigTimeDimension');

class ChartConfig {
  /**
   *
   * @param {string} name
   * @param {string} description
   * @param {string} type
   * @param {ChartConfigFilter[]} filters
   * @param {BarChartConfigDimensions} dimensions
   * @param {ChartConfigTable} table
   */
  constructor(name, description, type, filters, dimensions, table) {
    this.name = name;
    this.description = description;
    this.type = type;
    this.filters = filters;
    this.dimensions = dimensions;
    this.table = table;
  }

  /**
   * @param {string} axis
   * @return {string}
   */
  getDimensionType(axis) {
    const dimension = this.dimensions[axis];
    if (dimension instanceof TimeDimension) {
      return dimension.grouping.value;
    }
    return dimension.key;
  }
}

ChartConfig.TYPE_BAR = 'bar';
ChartConfig.TYPE_STACKED_BAR = 'stacked-bar';

module.exports = ChartConfig;
