'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var RevenuesInsightsReducer = require('../../apps/core/modules/insights/revenues/reducer');
var RevenuesInsightsStore = require('../../apps/core/modules/insights/revenues/store');
var RevenuesInsightsComponent = require('../../apps/core/modules/insights/revenues');

Wethod.module('InsightsApp', function (InsightsApp, Wethod) {
  InsightsApp.Controller = {
    showRevenuesInsights: function () {
      var layout = new InsightsApp.StructureLayoutView();
      layout.render();

      var sentence = Wethod.Utility
        .randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      layout.body.show(loadingView);

      var reducerWrapper = new RevenuesInsightsReducer({});

      var store = RevenuesInsightsStore(reducerWrapper.reduxReducer);
      var element = React.createElement(RevenuesInsightsComponent, { store: store });
      var container = document.getElementById('root');
      if (container !== null) {
        ReactDOM.render(element, container);
      }
    },
  };
});
