class RevenuesInsightsReducer {
  constructor(state) {
    this.state = {
      permissions: null,
      ...state,
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case 'ciao':
        return {
          ...state,
        };
      default:
        return state;
    }
  }
}

module.exports = RevenuesInsightsReducer;
