const ChartConfigDimension = require('./ChartConfigDimension');

class ChartConfigTimeDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigTimeDimensionGrouping} grouping
   */
  constructor(grouping) {
    super(ChartConfigTimeDimension.KEY);
    this.grouping = grouping;
  }
}

ChartConfigTimeDimension.KEY = 'time-view';

module.exports = ChartConfigTimeDimension;
