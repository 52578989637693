const React = require('react');
const IconLink = require('../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const Icon = require('../../../../../../common/react/Icon/Icon.react');

const PipelineLink = ({ projectId }) => (
  <IconLink label="Pipeline"
    borderless
    href={`#pipeline/projects?id=${projectId}`}
    target="_blank">
    <Icon icon="pipeline" />
  </IconLink>
);

module.exports = PipelineLink;
